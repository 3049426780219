import React, { useState } from "react";
import "./chooseus.scss";
import { Icon } from "@iconify/react";
function Chosseus() {
  const [tab, setTab] = useState("tab1");
  const handletab = (active) => {
    setTab(active);
  };

  return (
    <section className="chooseus-sec">
      <div className="inner">
        <div className="head">
          <div className="title-div">
            <p className="large title fw-medium text-inter">BENEFITS</p>
            <h3 className="text-white text-opensans">WHY CHOOSE US</h3>
          </div>
          <div className="arrow-div">
            <Icon icon="streamline:arrow-up-1" />
          </div>
        </div>
        <div className="detail">
          <div className="left">
            <div className="img-div">
              {tab === "tab1" ? (
                <img src="/assets/images/chooseus/1.webp" alt="..." />
              ) : tab === "tab2" ? (
                <img src="/assets/images/chooseus/2.webp" alt="..." />
              ) : tab === "tab3" ? (
                <img src="/assets/images/chooseus/3.webp" alt="..." />
              ) : tab === "tab4" ? (
                <img src="/assets/images/chooseus/4.webp" alt="..." />
              ) : null}
            </div>
            <img
              src="/assets/images/chooseus/backlayer.png"
              alt="..."
              className="back-layer"
            />
          </div>
          <div className="right">
            <div className="ist" onMouseEnter={() => handletab("tab1")}>
              <div className="content">
                <h5 className="fw-bold text-opensans">
                  Transparency and Security
                </h5>
                <p className="small fw-normal text-opensans">
                  The platform's integration with the Solana blockchain
                  guarantees immutable transaction records and robust security
                  measures, instilling trust among users.
                </p>
              </div>
              <h5 className="number fw-semibold text-opensans">01</h5>
            </div>
            <div className="ist" onMouseEnter={() => handletab("tab2")}>
              <div className="content">
                <h5 className="fw-bold text-opensans">
                  Comprehensive Solution
                </h5>
                <p className="small fw-normal text-opensans">
                  With Mister AI, you don't need to juggle multiple platforms or
                  services to fulfill your AI requirements. Our all-in-one
                  solution offers a diverse range of AI tools and capabilities,
                  ensuring that whatever your project or task may be, you'll
                  find the perfect tool within our platform.
                </p>
              </div>
              <h5 className="number fw-semibold text-opensans">02</h5>
            </div>
            <div className="ist" onMouseEnter={() => handletab("tab3")}>
              <div className="content">
                <h5 className="fw-bold text-opensans">Dedicated Support</h5>
                <p className="small fw-normal text-opensans">
                  At Mister AI, we believe in providing unparalleled support to
                  our users. Whether you have a question, need assistance with
                  implementation, or encounter a technical issue, our team of
                  experts is here to help.
                </p>
              </div>
              <h5 className="number fw-semibold text-opensans">03</h5>
            </div>
            <div className="ist" onMouseEnter={() => handletab("tab4")}>
              <div className="content">
                <h5 className="fw-bold text-opensans">Exclusive Features</h5>
                <p className="small fw-normal text-opensans">
                  Gain access to premium features and updates, including early
                  access to new tools, exclusive content, and advanced analytics
                  capabilities.
                </p>
              </div>
              <h5 className="number fw-semibold text-opensans">04</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Chosseus;
