import React from "react";
import "./secondheading.scss";
function Secondheading(props) {
  const { termColor, conditionColor, addBrTag } = props;
  return (
    <section className="secondheading-sec ">
      <div className="inner">
        {props.head1 && props.head2 && props.head3 ? (
          // <h1 className="first text-lightblack text-montserrat fw-bold">
          //   {props.head1} <br />{" "}
          //   <span className="text-main"> {props.head2} </span> {props.head3}
          // </h1>

          <h1
            className={`first text-lightblack text-montserrat fw-bold ${props.class}`}
          >
            <span className={termColor}>{props.head1}</span>
            {addBrTag && <br />}{" "}
            <span className={`text-black ist-text text-center w-100 `}>
              {props.head2}{" "}
            </span>
            <span className={termColor}>{props.head3}</span>
          </h1>
        ) : (
          <h1 className="second text-lightblack text-montserrat fw-bold">
            <span className="text-main"> {props.head1} </span> {props.head2}
          </h1>
        )}

        <p className="medium light-text text-montserrat fw-normal">
          {props.para}
        </p>
      </div>
    </section>
  );
}

export default Secondheading;
