import React from "react";
import "./features.scss";
import Heading from "../heading/heading";
import Button from "../button/button";
function Features() {
  const resumeurl = "https://app.misterai.io/resume";
  const summarizeurl = "https://app.misterai.io/summaros";
  const blogurl = "https://app.misterai.io/blog-builder";
  const imagifyurl = "https://app.misterai.io/img-gen";

  const resumeurlTab = () => {
    window.open(resumeurl, "_blank");
  };
  const summarizeTab = () => {
    window.open(summarizeurl, "_blank");
  };
  const blogTab = () => {
    window.open(blogurl, "_blank");
  };
  const imagifyTab = () => {
    window.open(imagifyurl, "_blank");
  };

  return (
    <section className="features-sec">
      <Heading maintext="FEATURES" subtext="Live Features" />
      <div className="inner">
        {/* resume */}

        <div className="ist">
          <div className="left">
            <img
              src="/assets/images/istfeature.webp"
              alt="..."
              className="back-img"
            />
            <img
              src="/assets/images/cvmaker.webp"
              alt="..."
              className="front-img"
            />
          </div>
          <div className="right">
            <div className="right-content">
              <div className="top d-flex align-items-center gap-2">
                <img
                  src="/assets/gifs/live.gif"
                  alt="..."
                  className="live-gif"
                  style={{ width: "38px", height: "38px" }}
                />
                <p className="small fw-semibold text-inter">Live</p>
              </div>
              <h5 className="text-montserrat fw-bold">
                AI
                <span className="text-main"> CV </span>
                MAKER
              </h5>
              <p className="text-montserrat fw-normal para">
                In today's competitive job market, creating a standout CV is
                essential for catching the eye of recruiters and landing your
                dream job. But what if there is a way to streamline this
                process, saving you time and effort while ensuring your CV is
                top-notch? Introducing AI CV Maker, the revolutionary AI-powered
                resume generation tool that simplifies resume creation. Say
                goodbye to hours spent formatting and structuring your CV, let
                us help you make a lasting impression!
              </p>
              <Button
                className="primary-btn small-btn fw-medium rounded"
                text="Generate  CV"
                width="193px"
                height="46px"
                onClick={resumeurlTab}
              />
            </div>
          </div>
        </div>

        {/* QUiz Booster */}
        <div className="second ">
          <div className="left">
            <div className="left-content">
              <div className="top d-flex align-items-center gap-2">
                <img
                  src="/assets/gifs/live.gif"
                  alt="..."
                  className="live-gif"
                  style={{ width: "38px", height: "38px" }}
                />
                <p className="small fw-semibold text-inter">Live</p>
              </div>
              <h5 className="text-montserrat fw-bold">
                AI
                <span className="text-main"> QUIZ </span>
                BOOSTER
              </h5>
              <p className="text-montserrat fw-normal para">
                Welcome to AI Quiz Booster, your ultimate destination for
                AI-generated quizzes! Whether you're looking to learn something
                new, prepare for a test, or simply have some fun, we've got you
                covered with quizzes spanning various genres and topics. Get
                ready to challenge your knowledge and expand your horizons with
                our diverse range of quizzes.
              </p>
              <Button
                className="primary-btn small-btn fw-medium rounded"
                text="Generate Quiz"
                width="193px"
                onClick={resumeurlTab}
                height="46px"
              />
            </div>
          </div>
          <div className="right">
            <img
              src="/assets/images/2ndfeature.webp"
              alt="..."
              className="back-img"
            />
            <img
              src="/assets/images/aiquiz.webp"
              alt="..."
              className="front-img"
            />
          </div>
        </div>

        {/* Blog Gen */}
        <div className="ist">
          <div className="left">
            <img
              src="/assets/images/istfeature.webp"
              alt="..."
              className="back-img"
            />
            <img
              src="/assets/images/aicontent.webp"
              alt="..."
              className="front-img"
            />
          </div>
          <div className="right">
            <div className="right-content">
              <div className="top d-flex align-items-center gap-2">
                <img
                  src="/assets/gifs/live.gif"
                  alt="..."
                  className="live-gif"
                  style={{ width: "38px", height: "38px" }}
                />
                <p className="small fw-semibold text-inter">Live</p>
              </div>
              <h5 className="text-montserrat fw-bold">
                AI
                <span className="text-main"> CONTENT </span>
                PRO
              </h5>
              <p className="text-montserrat fw-normal para">
                Do you find yourself spending hours researching and writing,
                only to end up with mediocre results? Say hello to AI Content
                Pro, the ultimate solution for all your content creation needs.
                AI Content Pro harnesses the power of artificial intelligence to
                help you write effortlessly in any niche or genre. Whether
                you're a blogger, marketer, or business owner, our platform is
                designed to streamline your writing process and produce
                high-quality content that resonates with your audience.
              </p>
              <Button
                className="primary-btn small-btn fw-medium rounded"
                text="Create Content"
                width="193px"
                onClick={blogTab}
                height="46px"
              />
            </div>
          </div>
        </div>

        {/* IMAGIFY */}
        <div className="second">
          <div className="left">
            <div className="left-content">
              <div className="top d-flex align-items-center gap-2">
                <img
                  src="/assets/gifs/live.gif"
                  alt="..."
                  className="live-gif"
                  style={{ width: "38px", height: "38px" }}
                />
                <p className="small fw-semibold text-inter">Live</p>
              </div>
              <h5 className="text-montserrat fw-bold">
                AI
                <span className="text-main"> IMAGIFY </span>
              </h5>
              <p className="text-montserrat fw-normal para">
                AI Imagify is a super-smart image-generation tool that creates
                amazing images for you in just seconds. Whether you need a
                picture for your blog, website, or Instagram, AI Imagify has got
                you covered! Tailor your images to match your vision perfectly
                with AI Imagify which brings your imagination to life in mere
                seconds. Whether you're a graphic designer, marketer, or content
                creator, AI Imagify seamlessly integrates into your workflow.
              </p>
              <Button
                className="primary-btn small-btn fw-medium rounded"
                text="Generate Images"
                width="193px"
                onClick={imagifyTab}
                height="46px"
              />
            </div>
          </div>
          <div className="right">
            <img
              src="/assets/images/2ndfeature.webp"
              alt="..."
              className="back-img"
            />
            <img
              src="/assets/images/aiimagify.webp"
              alt="..."
              className="front-img"
            />
          </div>
        </div>

        {/* SUMMAROS */}
        <div className="ist">
          <div className="left">
            <img
              src="/assets/images/istfeature.webp"
              alt="..."
              className="back-img"
            />
            <img
              src="/assets/images/aisummaros.webp"
              alt="..."
              className="front-img"
            />
          </div>
          <div className="right">
            <div className="right-content">
              <div className="top d-flex align-items-center gap-2">
                <img
                  src="/assets/gifs/live.gif"
                  alt="..."
                  className="live-gif"
                  style={{ width: "38px", height: "38px" }}
                />
                <p className="small fw-semibold text-inter">Live</p>
              </div>
              <h5 className="text-montserrat fw-bold">
                AI
                <span className="text-main"> SUMMAROS </span>
              </h5>
              <p className="text-montserrat fw-normal para">
                Gone are the days of spending hours poring over extensive
                documents with AI Summaros, you can extract the most pertinent
                details in a matter of minutes. AI Summaros is a revolutionary
                summary generation tool designed to streamline the process of
                digesting lengthy documents. Whether it's a PDF, DOCX, CSV, or
                plain text file, AI Summaros can quickly generate concise
                summaries, highlighting key points and essential information.
              </p>
              <Button
                className="primary-btn small-btn fw-medium rounded"
                text="Simplify Document"
                width="193px"
                onClick={summarizeTab}
                height="46px"
              />
            </div>
          </div>
        </div>
      </div>

      <img
        src="/assets/images/featuresleft.png"
        alt="..."
        className="features-left"
      />
      <img
        src="/assets/images/featuresright.png"
        alt="..."
        className="features-right"
      />
    </section>
  );
}

export default Features;
