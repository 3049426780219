import React from "react";
import "./heading.scss";
function Heading(props) {
  return (
    <section className="heading-sec">
      <h1>{props.maintext}</h1>
      <h2 className="fw-bold text-lightblack text-montserrat">
        {props.subtext}
      </h2>
    </section>
  );
}

export default Heading;
