import React from "react";
import "./footer.scss";
import Button from "../button/button";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <section className="footer-sec">
      <div className="footer-top">
        <div className="inner">
          <div className="left">
            <img
              src="/assets/images/footermain.webp"
              alt="..."
              className="footermain-img"
            />
          </div>
          <div className="right">
            <div className="content">
              <p className="large fw-medium text-jakarta text-black">
                30-DAY FREE TRIAL
              </p>
              <h1
                className="text-montserrat fw-bold"
                style={{ lineHeight: "110%" }}
              >
                Empower Your Journey with AI
              </h1>
              <p className="small text-jakarta fw-normal text-black ">
                64,921 weekly users
              </p>
            </div>
            <div className="input-div rounded-pill">
              <input
                type="text"
                className="subscribe-input fw-normal"
                placeholder="Subscribe to newsletter"
              />
              <Button
                className="primary-btn large-btn fw-medium rounded-pill"
                text="Subscribe"
                width="189px"
                height="53px"
              />
            </div>
          </div>
        </div>
        <img
          src="/assets/images/minion.webp"
          alt="..."
          className="minion-img"
        />
        <img
          src="/assets/images/footer/leftback.webp"
          alt="..."
          className="footerback-left"
        />
        <img
          src="/assets/images/footer/rightback.webp"
          alt="..."
          className="footerback-right"
        />
      </div>
      <div className="footer-bottom">
        <div className="social-icons">
          <p
            className="small fw-normal text-montserrat"
            style={{ color: "#B1B0B6" }}
          >
            Follow Us :
          </p>
          <a href="https://t.me/misteraicommunity" target="_blank">
            <img src="/assets/icons/telegramFooter.png" alt="..." />
          </a>
          <a href="https://x.com/misterai_info" target="_blank">
            <img src="/assets/icons/footer/twitter.svg" alt="..." />
          </a>
          <a href="https://www.linkedin.com/company/mister-ai/" target="_blank">
            <img src="/assets/icons/footer/linkedin.svg" alt="..." />
          </a>
          <a href="https://discord.gg/XKZGnArzYK" target="_blank">
            <img src="/assets/icons/discordFooter.png" alt="..." />
          </a>
          {/* <a href="#">
            <img src="/assets/icons/footer/discord.svg" alt="..." />
          </a> */}
          <a href="https://www.tiktok.com/@misterai_off" target="_blank">
            <img src="/assets/icons/footer/tiktok.svg" alt="..." />
          </a>
        </div>
        <div className="privacy-div">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-&-conditions">Terms of Conditions</Link>
        </div>
        <p className="fw-normal text-montserrat">
          Copyright © 2024 MR AI. All Rights Reserved
        </p>
      </div>
    </section>
  );
}

export default Footer;
