import React from "react";
import HelpYou from "./helpYou";
import SummarizeContent from "./summarizeContent";
import Features from "./features";
import SimplifyLife from "./simplifyLife";
import RoboSection from "./roboSection";
import Benefits from "./benefits";
import Trusted from "../trusted/trusted";
import Secondheading from "../secondheading/secondheading";
import blackBorder from "../../assets/images/aiSummaros/blackBorderbbb.webp";
import Heading from "../heading/heading";

const AiSummaros = () => {
  const imgDash = blackBorder;
  return (
    <div className="top-sec-padding">
      <Secondheading
        head1="Summarize Your"
        head2={["Content", "Documents", "Information "]}
        head3="With us"
        para="Let us simplify your content and documents, delivering concise summaries for easier understanding and decision-making."
        addBrTag={true}
      />
      <HelpYou
        typewriterMessage={[
          "Generate  the summary of the paragraph attached below.",
          "Summarize the research paper of artificial intelligence and it's benefits.",
          "Provide the summary of the latest development in quantum computing.",
        ]}
      />
      <SummarizeContent imgs={imgDash} />
      <Heading maintext="FEATURES" subtext="AI Summaros Features" />
      <Features />
      <SimplifyLife />
      <RoboSection
        h1="Simply Summarize with Our"
        span=" AI Summaros "
        p="AI Summaros is a tool by Mister AI aimed to bring ease to the professional workflow and save quality time by summarizing long texts into viable abstracts.  "
      />
      <Heading maintext="BENEFITS" subtext="AI Summaros Benefits" />
      <Benefits />
      <Trusted />
    </div>
  );
};

export default AiSummaros;
