import React from "react";
import "./aiImages.scss";
function AiImages() {
  return (
    <section className="aiImages-sec">
      <div className="inner">
        <div className="images-divs">
          <div className="first">
            <img src="/assets/images/ai images/1.webp" alt="..." />
            <img src="/assets/images/ai images/2.webp" alt="..." />
          </div>
          <div className="second">
            <img src="/assets/images/ai images/3.webp" alt="..." />
            <img src="/assets/images/ai images/4.webp" alt="..." />
          </div>
          <div className="third">
            <img src="/assets/images/ai images/5.webp" alt="..." />
            <img src="/assets/images/ai images/6.webp" alt="..." />
          </div>
          <div className="forth">
            <img src="/assets/images/ai images/7.webp" alt="..." />
            <img src="/assets/images/ai images/8.webp" alt="..." />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AiImages;
