import React, { useEffect, useState, useRef } from "react";
import "../demo/contactForm.scss";
import Button from "../button/button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Perform validation
    const errors = validate(formValues);
    setFormErrors(errors);

    // If there are errors, do not send the email
    if (Object.keys(errors).length > 0) {
      console.log("Validation errors:", errors);
      // toast.error("Please fix the errors before submitting.");
      return; // Stop the function from continuing
    }

    // If no errors, proceed to send the email
    emailjs
      .sendForm("service_gxhaiqf", "template_0wqw4bs", form.current, {
        publicKey: "g_pMyLWUUZP75b4aU",
      })
      .then(
        () => {
          toast.success("Request sent successfully!", {
            toastId: "demosuccess",
          });
          setFormValues(initialValues);
        },
        (error) => {
          console.error("Email send failed:", error);
          toast.error("Failed to send email. Please try again later.", {
            toastId: "err",
          });
        }
      );
  };

  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  //const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(e.target);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
  };

  useEffect(() => {
    if (Object.keys(formErrors).length == 0 && isSubmit);
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    const phoneRegex = /^\d{11}$/;
    let check = false;
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!values.fname) {
      errors.fname = "First name is required";
      check = true;
    }

    if (!values.lname) {
      errors.lname = "Last name is required";
      check = true;
    }
    if (!values.email) {
      errors.email = "Email is required";
      check = true;
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid Email Format";
      check = true;
    }
    if (!values.phone) {
      errors.phone = "Phone number is required";
      check = true;
    } else if (!phoneRegex.test(values.phone)) {
      errors.phone = "Please enter a valid 11-digit phone number";
    }
    if (!values.message) {
      errors.message = "Message is required";
      check = true;
    }
    if (!check) {
      setFormValues(initialValues);
    }
    return errors;
  };
  console.log(formValues);

  return (
    <>
      <div className="formWrapper">
        <div className="form-left-content">
          <div className="mainDiv">
            <h3 className="text-montserrat fw-bold">
              Get a Free Demo Today by
              <br className="d-sm-none d-block" />
              <span className="text-main "> Signing Up!</span>
            </h3>
            <p className="small fw-normal text-opensans">
              Elevate your experience with a personalized demo upon signing up
              today. Don't miss out!
            </p>
          </div>
        </div>
        <div className="form-right-content">
          <form ref={form} onSubmit={sendEmail}>
            <div className="name-fields d-flex gap-3">
              <div className="w-100">
                <input
                  className="input-fields small"
                  type="text"
                  placeholder="First Name*"
                  value={formValues.fname}
                  onChange={handleChange}
                  name="fname"
                />
                <span style={{ color: "red" }} className="validation-error">
                  {formErrors.fname}
                </span>
              </div>

              <div className="w-100">
                <input
                  className="input-fields small"
                  type="text"
                  placeholder="Last Name*"
                  value={formValues.lname}
                  onChange={handleChange}
                  name="lname"
                />
                <span style={{ color: "red" }} className="validation-error">
                  {formErrors.lname}
                </span>
              </div>
            </div>

            <div className="w-100">
              <input
                className="input-fields small"
                type="email"
                placeholder="Email*"
                value={formValues.email}
                onChange={handleChange}
                name="email"
              />
              <span style={{ color: "red" }} className="validation-error">
                {formErrors.email}
              </span>
            </div>

            <div className="w-100">
              <input
                className="input-fields small"
                type="number"
                placeholder="Phone Number*"
                value={formValues.phone}
                onChange={handleChange}
                name="phone"
                inputMode="numeric"
              />
              <span style={{ color: "red" }} className="validation-error">
                {formErrors.phone}
              </span>
            </div>
            <div className="w-100">
              <textarea
                value={formValues.message}
                onChange={handleChange}
                className="text-area small text-opensans fw-normal"
                placeholder="Your Message..."
                name="message"
              ></textarea>
              <span style={{ color: "red" }} className="validation-error">
                {formErrors.message}
              </span>
            </div>

            <Button
              className="primary-btn small-btn fw-semibold rounded"
              text="Send Request"
              width="100%"
              height="57px"
              // onClick={handleSubmit}
              type="submit"
            />
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ContactForm;
