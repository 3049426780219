import React from "react";
import "../aiSummaros/simplifyLife.scss";
import tick from "../../assets/images/aiSummaros/yellowCircle.svg";
import rightContent from "../../assets/images/aiSummaros/rightContent.webp";
import leftGlow from "../../assets/images/aiSummaros/simplifyGlow.webp";
import rightGlow from "../../assets/images/aiSummaros/simplify-right-glow.webp";

const SimplifyLife = () => {
  return (
    <>
      <div className="simplifyWrapper">
        <img className="leftGlow" src={leftGlow} alt="" />
        <img className="rightGlow" src={rightGlow} alt="" />

        <div className="inner">
          <div className="leftArea">
            <h2 className="text-montserrat fw-bold">
              Zero compromise on quality With {" "}
              <span className="text-main">AI Summaros</span>
            </h2>
            <p className="medium text-montserrat fw-normal">
              AI Summaros by Mister AI ensures that no corners are cut in
              delivering quality content with no loss of information in the
              final summary.
            </p>
            <div className="checkBoxWrappper">
              <li>
                <img src={tick} alt="" />
                <p className="text-montserrat fw-medium large text-black">
                Accurate 
                </p>
              </li>

              <li>
                <img src={tick} alt="" />
                <p className="text-montserrat fw-medium large text-black">
                Relevant 
                </p>
              </li>

              <li>
                <img src={tick} alt="" />
                <p className="text-montserrat fw-medium large text-black">
                Concise 
                </p>
              </li>

              <li>
                <img src={tick} alt="" />
                <p className="text-montserrat fw-medium large text-black ">
                Customizable 
                </p>
              </li>
            </div>
          </div>
          <div className="rightArea">
            <img src={rightContent} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SimplifyLife;
