import React, { useEffect, useRef, useState } from "react";
import "./navbar.scss";
import Button from "../button/button";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Hamburger from "hamburger-react";
import Offcanvas from "react-bootstrap/Offcanvas";

function Navbar() {
  const [isOpen, setOpen] = useState(false);
  const [isShow, setShow] = useState(false);
  const [tabData, setTabData] = useState(null);
  const location = useLocation();
  const [showcanvas, setShowcanvas] = useState(false);

  const handleClosecanvas = () => {
    setOpen(false);
    setShowcanvas(false);
  };
  const handleShowcanvas = () => setShowcanvas(true);
  const solutionsRef = useRef(null);
  const solutionRef = useRef(null);

  const navigate = useNavigate();
  const handleclick = () => {
    setOpen(false);
  };
  const handleclose = () => {
    setShow(false);
  };
  const mouseclose = () => {
    setTabData(null);
  };
  const handleshow = () => {
    setShow(!isShow);
  };
  const url = "https://app.misterai.io/sign-in"; // Replace with your desired URL

  const openInNewTab = () => {
    window.open(url, "_blank");
    setOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        solutionsRef.current &&
        !solutionsRef.current.contains(event.target) &&
        solutionRef.current &&
        !solutionRef.current.contains(event.target)
      ) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log(tabData);
  }, [tabData]);
  const data = [
    {
      head: "AI CV maker",
      content:
        "Introducing our amazing AI CV maker! It helps you create a professional resume super fast. No need to spend hours figuring out how to format or what to write. Just tell it about yourself, and it does the hard work for you. It makes your resume look great and shows off your skills and experience. Whether you're new to the job market or have lots of experience, our AI CV maker makes it easy for you. And don't worry, your information is safe with us. Try our AI CV maker today and make getting your dream job a breeze!",
      id: 1,
    },
    {
      head: "AI Quiz Booster",
      content:
        "Welcome to AI Quiz Booster! It's like a fun playground for quizzes. You can find quizzes on all sorts of topics, like history, science, movies, and more. Whether you want to learn new things or just have a good time, AI Quiz Booster has something for you. It's super easy to use, and the quizzes change based on how well you're doing, so they're always just right for you. Come join the fun and start quizzing with AI Quiz Booster today!",
      id: 2,
    },
    {
      head: "AI Content Pro",
      content:
        "Say goodbye to writer's block with AI Content Pro! Our advanced AI-powered platform takes the hassle out of content creation, allowing you to effortlessly write in any niche or genre.  Whether you write blogs, ads, or anything else, AI Content Pro can help. It gives you ideas and fixes mistakes so your writing looks awesome. Give AI Content Pro a try and see how much easier writing can be!",
      id: 3,
    },

    // {
    //   head: "AI Vocals",
    //   content:
    //     "AI Vocals revolutionize the way you interact with technology by bringing advanced voice capabilities to your fingertips. Whether you're a content creator, business professional, or someone who just wants to simplify daily tasks, AI Vocals is your ultimate voice assistant.",
    //   id: 4,
    // },
    {
      head: "AI Imagify",
      content:
        "Elevate your designs effortlessly with AI Imagify. This innovative tool revolutionizes image creation, Just tell it what you want, pick a style, and boom – your image is ready in no time! Whether you're making a blog, a website, or posting on social media, AI Imagify has your back. It's easy to use and fits right into your work. So say goodbye to long waits and hello to amazing designs with AI Imagify!",
      id: 4,
    },
    {
      head: "AI SUMMAROS",
      content:
        "AI Summaros is a tool that helps you understand complicated documents more easily. It quickly takes big documents, like PDFs or Word files, and makes short summaries of them. You can even choose how detailed you want the summary to be. This tool finds the important parts of the document and gives you the main ideas, saving you time and making it simpler to grasp what the document is about. Whether you're a student, a researcher, or someone working in a company, AI Summaros makes it easier to understand important information and make decisions faster.",
      id: 5,
    },
  ];
  const handletab = (tabId) => {
    const tab = data.find((item) => item.id === parseInt(tabId.slice(3)));
    if (tab) {
      setTabData(tab);
    }
  };
  const activeTabArray = [
    "/cv-maker",
    "/ai-quiz",
    "/content-pro",
    "/ai-imagify",
    "/ai-summaros",
  ];

  return (
    <>
      <section className="navbar-sec desktop-nav">
        <div className="inner">
          <div className="left">
            <img
              src="/assets/icons/finallogo.svg"
              alt="..."
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="center">
            <NavLink
              to="/"
              className="fw-normal text-opensans"
              onClick={() => handleclose()}
            >
              Home
            </NavLink>
            <a
              onClick={() => handleshow()}
              ref={solutionRef}
              className={`fw-normal text-opensans ${
                activeTabArray.includes(location.pathname) ? "active" : ""
              }`}
            >
              Solutions
              <img
                src="/assets/icons/downarrow.svg"
                alt=""
                className={`ms-2 ${isShow ? "activeimg" : ""}`}
              />
            </a>
            <NavLink
              to="/pricing"
              className="fw-normal text-opensans"
              onClick={() => handleclose()}
            >
              Pricing
            </NavLink>
            <NavLink
              to="/demo"
              className="fw-normal text-opensans"
              onClick={() => handleclose()}
            >
              Demo
            </NavLink>

            <a
              href="https://mister-ai.s3.ap-southeast-1.amazonaws.com/misteraipitchdeck"
              target="_blank"
              className="fw-normal text-opensans"
              // onClick={() => handleclose()}
            >
              Pitch Deck
            </a>

            <a
              href="https://mister-ai.gitbook.io/misteraiwhitepaper"
              className="fw-normal text-opensans"
              target="_blank"
              onClick={() => handleclose()}
            >
              Whitepaper
            </a>

            <div
              className={isShow ? "solutions-div" : "not-solution"}
              ref={solutionsRef}
            >
              <div className="tab-side">
                <label>Industries we Target</label>
                <NavLink
                  to="/cv-maker"
                  onMouseEnter={() => handletab("tab1")}
                  onMouseLeave={() => mouseclose()}
                  onClick={() => handleclose()}
                  className="tab-item"
                >
                  <span>AI CV Maker</span>
                  <img src="/assets/icons/navbar/next.svg" alt="" />
                </NavLink>
                <NavLink
                  onMouseEnter={() => handletab("tab2")}
                  onMouseLeave={() => mouseclose()}
                  onClick={() => handleclose()}
                  className="tab-item"
                  to="/ai-quiz"
                >
                  <span>AI Quiz Booster</span>
                  <img src="/assets/icons/navbar/next.svg" alt="" />
                </NavLink>
                <NavLink
                  onMouseEnter={() => handletab("tab3")}
                  onMouseLeave={() => mouseclose()}
                  onClick={() => handleclose()}
                  className="tab-item"
                  to="/content-pro"
                >
                  <span>AI Content Pro</span>
                  <img src="/assets/icons/navbar/next.svg" alt="" />
                </NavLink>
                <NavLink
                  onMouseEnter={() => handletab("tab4")}
                  onMouseLeave={() => mouseclose()}
                  onClick={() => handleclose()}
                  className="tab-item"
                  to="/ai-imagify"
                >
                  <span>AI Imagify</span>
                  <img src="/assets/icons/navbar/next.svg" alt="" />
                </NavLink>
                <NavLink
                  onMouseEnter={() => handletab("tab5")}
                  onMouseLeave={() => mouseclose()}
                  onClick={() => handleclose()}
                  className="tab-item"
                  to="/ai-summaros"
                >
                  <span>AI Summaros</span>
                  <img src="/assets/icons/navbar/next.svg" alt="" />
                </NavLink>
              </div>
              {tabData && (
                <div data-aos="zoom-in-right" className="content-side">
                  <span className="head fw-semibold text-opensans text-black">
                    {tabData.head}
                  </span>
                  <span className="content fw-medium text-montserrat text-black">
                    {tabData.content}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="right">
            <NavLink>
              <Button
                className="secondary-btn large-btn fw-medium rounded"
                text="Login"
                width="119px"
                height="58px"
                onClick={openInNewTab}
              />
            </NavLink>

            <NavLink to="/demo">
              <Button
                className="primary-btn large-btn fw-medium rounded"
                text="Contact us"
                width="171px"
                height="58px"
              />
            </NavLink>
          </div>
        </div>
      </section>
      <section className="mobile-nav">
        <div className="topbar">
          <img
            src="/assets/icons/finallogo.svg"
            alt="..."
            className="mobile-logo"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          />
          <div className="right">
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
        </div>

        <div className={isOpen ? "menus" : "sidebar"}>
          <div className="first">
            <NavLink
              to="/"
              className="fw-normal text-opensans"
              onClick={() => handleclick()}
            >
              Home
            </NavLink>
            <a
              id="solutionTab "
              className={`fw-normal text-opensans ${
                activeTabArray.includes(location.pathname) ? "active" : ""
              }`}
              onClick={() => handleShowcanvas()}
            >
              Solutions
            </a>
            <NavLink
              to="/pricing"
              className="fw-normal text-opensans"
              onClick={() => handleclick()}
            >
              Pricing
            </NavLink>
            <NavLink
              to="/demo"
              className="fw-normal text-opensans"
              onClick={() => handleclick()}
            >
              Demo
            </NavLink>

            <a
              href="https://mister-ai.s3.ap-southeast-1.amazonaws.com/misteraipitchdeck"
              target="_blank"
              className="fw-normal text-opensans"
              // onClick={() => handleclose()}
            >
              Pitch Deck
            </a>
            <a
              href="https://mister-ai.gitbook.io/misteraiwhitepaper"
              className="fw-normal text-opensans"
              target="_blank"
              onClick={() => handleclose()}
            >
              Whitepaper
            </a>
          </div>
          <div className="buttons">
            <Button
              className="secondary-btn large-btn fw-medium rounded"
              text="Login"
              width="110px"
              height="40px"
              onClick={openInNewTab}
            />
            <NavLink to="/demo">
              <Button
                className="primary-btn large-btn fw-medium rounded"
                text="Contact us"
                width="110px"
                height="40px"
                onClick={() => handleclick()}
              />
            </NavLink>
          </div>
        </div>

        <Offcanvas
          show={showcanvas}
          onHide={handleClosecanvas}
          className="solution-offcanvas"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="tab-side">
              <div className="inner">
                <label>Industries we Target</label>
                <NavLink
                  to="/cv-maker"
                  onClick={() => handleClosecanvas()}
                  className="tab-item"
                >
                  <span>AI CV Maker</span>
                  <img src="/assets/icons/navbar/next.svg" alt="" />
                </NavLink>
                <NavLink
                  onClick={() => handleClosecanvas()}
                  className="tab-item"
                  to="/ai-quiz"
                >
                  <span>AI Quiz Booster</span>
                  <img src="/assets/icons/navbar/next.svg" alt="" />
                </NavLink>
                <NavLink
                  onClick={() => handleClosecanvas()}
                  className="tab-item"
                  to="/content-pro"
                >
                  <span>AI Content Pro</span>
                  <img src="/assets/icons/navbar/next.svg" alt="" />
                </NavLink>
                <NavLink
                  onClick={() => handleClosecanvas()}
                  className="tab-item"
                  to="/ai-imagify"
                >
                  <span>AI Imagify</span>
                  <img src="/assets/icons/navbar/next.svg" alt="" />
                </NavLink>
                <NavLink
                  onClick={() => handleClosecanvas()}
                  className="tab-item"
                  to="/ai-summaros"
                >
                  <span>AI Summaros</span>
                  <img src="/assets/icons/navbar/next.svg" alt="" />
                </NavLink>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </section>
    </>
  );
}

export default Navbar;
