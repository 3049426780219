import React from "react";
import "../aiSummaros/benefitCard.scss";

const BenefitCard = (props) => {
  return (
    <div className={`cardWrapper ${props.border1}  ${props.bBottom}`}>
      <div className="inner">
        
        <img src={props.imgs} alt="" />
        <h6 className="text-montserrat fw-bold h-center">{props.heading}</h6>
        <p className="small text-montserrat fw-normal">{props.paragraph}</p>
      </div>
    </div>
  );
};

export default BenefitCard;
