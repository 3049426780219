import React from "react";
import "../contentPro/aiAssistent.scss";
import aiAssist from "../../assets/images/contentPro/seoAnalization.webp";

const AiAssistent = () => {
  return (
    <>
      <div className="assistentDiv">
        <div className="headingDiv">
          <h4 className="fw-bold text-montserrat">
          Streamline Writing with Intelligent
          </h4>
          <h4 className="text-main fw-bold text-montserrat">AI Assistant</h4>
        </div>
        <div className="inner">
          <img src={aiAssist} alt="" />
        </div>
      </div>
    </>
  );
};

export default AiAssistent;
