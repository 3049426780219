import React, { useEffect } from "react";
import "../termCondition/termCondition.scss";
import Secondheading from "../secondheading/secondheading";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="term-wrapper">
        <Secondheading
          head1="Privacy"
          head2="Policy"
          para="Privacy policy is a statement or legal document that explains how a website or app collects, uses, and protects the personal information of its users. It informs users about their privacy rights and how their data will be handled."
        />
        <div className="textArea">
          <h6 className="text-montserrat fw-bold mt-4">Introduction</h6>
          <p className="text-montserrat my-2 fw-medium para">
            At Mister AI, we are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy explains how we collect, use, and protect your data when you
            use our website and services.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">
            Information We Collect
          </h6>
          <p className="text-montserrat my-2 fw-medium para">
            We collect information from you when you use our AI Image Generator,
            AI Summarizer, AI Quiz Booster, and other services. This information
            may include:
          </p>
          <p className="text-montserrat fw-medium   ">
            - Personal information such as your name, email address, and phone
            number.
          </p>
          <p className="text-montserrat fw-medium   ">
            - Usage information such as your IP address, browser type, and
            operating system.
          </p>
          <p className="text-montserrat fw-medium mb-2   ">
            - Information collected through cookies and similar technologies.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">
            How We Use Your Information
          </h6>
          <p className="text-montserrat my-2 fw-medium para">
            We use the information we collect to provide you with our services,
            improve our website and services, and communicate with you. This may
            include:
          </p>
          <p className="text-montserrat fw-medium   ">
            - Personalizing your experience on our website.
          </p>

          <p className="text-montserrat fw-medium   ">
            - Sending you promotional emails and other communications.
          </p>

          <p className="text-montserrat fw-medium mb-2   ">
            - Analyzing usage trends and preferences to improve our services.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">Data Security</h6>
          <p className="text-montserrat my-2 fw-medium para">
            We take the security of your data seriously and take measures to
            protect it. This includes using encryption technology to secure your
            personal information and regularly updating our security practices.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">
            Sharing of Information
          </h6>
          <p className="text-montserrat my-2 fw-medium para">
            We may share your information with third parties for purposes such
            as processing payments, providing customer support, and analyzing
            usage trends. However, we do not sell or rent your personal
            information to third parties for marketing purposes.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">Your Rights</h6>
          <p className="text-montserrat my-2 fw-medium para">
            You have the right to access, update, and delete your personal
            information. You can also opt-out of receiving promotional emails
            from us. To exercise these rights, please contact us using the
            information provided below.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">
            Changes to This Privacy Policy
          </h6>
          <p className="text-montserrat my-2 fw-medium para">
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page, and we encourage you to review this
            policy periodically.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">Contact Us</h6>
          <p className="text-montserrat my-2 fw-medium para">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at
            [contact@misterai.com](mailto:contact@misterai.com).
          </p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
