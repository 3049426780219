import React from "react";
import "../aiSummaros/featureCard.scss";

const FeatureCard = (props) => {
  return (
    <>
      <div className="featureCard">
        <img src={props.imgs} alt="" />
        <h5 className="large text-montserrat fw-bold">{props.heading}</h5>
        <p className="fw-normal small">
       {props.paragraph}
        </p>
      </div>
    </>
  );
};

export default FeatureCard;
