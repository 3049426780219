import React from "react";
import Navbar from "../navbar/navbar";
import Hero from "../hero/hero";
import About from "../about/about";
import Features from "../features/features";
import Wesay from "../wesay/wesay";
import Chosseus from "../chooseus/chosseus";
import Statistics from "../statistics/statistics";
import Roadmap from "../roadmap/roadmap";
import Blogs from "../blogs/blogs";
import Faqs from "../faqs/faqs";
import AiImages from "../aiImages/aiImages";
function Home() {
  return (
    <div>
      <Hero />
      <About />
      <Features />
      <Wesay />
      <Chosseus />
      <Statistics />
      <Roadmap />
      <Blogs />
      <Faqs />
    </div>
  );
}

export default Home;
