import React, { useState } from "react";
import "./wesay.scss";
import Heading from "../heading/heading";
import { Modal } from "react-bootstrap";
import Button from "../button/button";

function Wesay() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const handleButtonClick = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <section className="wesay-sec">
      <Heading maintext="WE SAY" subtext="One Stop Shop" />
      <img
        src="/assets/images/bottomarrow.svg"
        alt="..."
        className="heading-img"
      />
      <div className="inner-desktop">
        <div className="ist">
          <button
            className="btn2 ist-color"
            onClick={() => handleButtonClick(data[0])}
          >
            {data[0].head}
          </button>
          <button
            className="btn1 second-color"
            onClick={() => handleButtonClick(data[1])}
          >
            {data[1].head}
          </button>
          <button
            className="btn3 third-color"
            onClick={() => handleButtonClick(data[2])}
          >
            {data[2].head}
          </button>
          <button
            className="btn2 forth-color"
            onClick={() => handleButtonClick(data[3])}
          >
            {data[3].head}
          </button>
          <button
            className="btn3 fifth-color"
            onClick={() => handleButtonClick(data[4])}
          >
            {data[4].head}
          </button>
        </div>
        <div className="second">
          <button
            className="btn1 sixth-color"
            onClick={() => handleButtonClick(data[5])}
          >
            {data[5].head}
          </button>
          <button
            className="btn3 seventh-color"
            onClick={() => handleButtonClick(data[6])}
          >
            {data[6].head}
          </button>
          <button
            className="btn2 eighth-color"
            onClick={() => handleButtonClick(data[7])}
          >
            {data[7].head}
          </button>
          <button
            className="btn3 nineth-color"
            onClick={() => handleButtonClick(data[8])}
          >
            {data[8].head}
          </button>
          <button
            className="btn1 tenth-color"
            onClick={() => handleButtonClick(data[9])}
          >
            {data[9].head}
          </button>
        </div>
        <div className="third">
          <button
            className="btn2 fifth-color"
            onClick={() => handleButtonClick(data[10])}
          >
            {data[10].head}
          </button>
          <button
            className="btn2 forth-color"
            onClick={() => handleButtonClick(data[11])}
          >
            {data[11].head}
          </button>
          <button
            className="btn3 third-color"
            onClick={() => handleButtonClick(data[12])}
          >
            {data[12].head}
          </button>
          <button
            className="btn1 second-color"
            onClick={() => handleButtonClick(data[13])}
          >
            {data[13].head}
          </button>
          <button
            className="btn3 ist-color"
            onClick={() => handleButtonClick(data[14])}
          >
            {data[14].head}
          </button>
        </div>
        <div className="forth">
          <button
            className="btn1 tenth-color"
            onClick={() => handleButtonClick(data[15])}
          >
            {data[15].head}
          </button>
          <button
            className="btn3 nineth-color"
            onClick={() => handleButtonClick(data[16])}
          >
            {data[16].head}
          </button>
          <button
            className="btn2 eighth-color"
            onClick={() => handleButtonClick(data[17])}
          >
            {data[17].head}
          </button>
          <button
            className="btn3 seventh-color"
            onClick={() => handleButtonClick(data[18])}
          >
            {data[18].head}
          </button>
          <button
            className="btn1 sixth-color"
            onClick={() => handleButtonClick(data[19])}
          >
            {data[19].head}
          </button>
        </div>
        <div className="fifth">
          <button
            className="btn3 ist-color"
            onClick={() => handleButtonClick(data[20])}
          >
            {data[20].head}
          </button>
          <button
            className="btn2 second-color"
            onClick={() => handleButtonClick(data[21])}
          >
            {data[21].head}
          </button>
          <button
            className="btn3 third-color"
            onClick={() => handleButtonClick(data[22])}
          >
            {data[22].head}
          </button>
          <button
            className="btn1 forth-color"
            onClick={() => handleButtonClick(data[23])}
          >
            {data[23].head}
          </button>
          <button
            className="btn2 fifth-color"
            onClick={() => handleButtonClick(data[24])}
          >
            {data[24].head}
          </button>
        </div>
        <div className="sixth">
          <button className="btn1 invisible"></button>
          <button
            className="btn3 nineth-color"
            onClick={() => handleButtonClick(data[25])}
          >
            {data[25].head}
          </button>
        </div>
      </div>
      <div className="inner-mob">
        <button
          className="btn2 ist-color"
          onClick={() => handleButtonClick(data[0])}
        >
          {data[0].head}
        </button>
        <button
          className="btn1 second-color"
          onClick={() => handleButtonClick(data[1])}
        >
          {data[1].head}
        </button>
        <button
          className="btn3 third-color"
          onClick={() => handleButtonClick(data[2])}
        >
          {data[2].head}
        </button>
        <button
          className="btn2 forth-color"
          onClick={() => handleButtonClick(data[3])}
        >
          {data[3].head}
        </button>
        <button
          className="btn3 fifth-color"
          onClick={() => handleButtonClick(data[4])}
        >
          {data[4].head}
        </button>

        <button
          className="btn1 sixth-color"
          onClick={() => handleButtonClick(data[5])}
        >
          {data[5].head}
        </button>
        <button
          className="btn3 seventh-color"
          onClick={() => handleButtonClick(data[6])}
        >
          {data[6].head}
        </button>
        <button
          className="btn2 eighth-color"
          onClick={() => handleButtonClick(data[7])}
        >
          {data[7].head}
        </button>
        <button
          className="btn3 nineth-color"
          onClick={() => handleButtonClick(data[8])}
        >
          {data[8].head}
        </button>
        <button
          className="btn1 tenth-color"
          onClick={() => handleButtonClick(data[9])}
        >
          {data[9].head}
        </button>

        <button
          className="btn2 fifth-color"
          onClick={() => handleButtonClick(data[10])}
        >
          {data[10].head}
        </button>
        <button
          className="btn2 forth-color"
          onClick={() => handleButtonClick(data[11])}
        >
          {data[11].head}
        </button>
        <button
          className="btn3 third-color"
          onClick={() => handleButtonClick(data[12])}
        >
          {data[12].head}
        </button>
        <button
          className="btn1 second-color"
          onClick={() => handleButtonClick(data[13])}
        >
          {data[13].head}
        </button>
        <button
          className="btn3 ist-color"
          onClick={() => handleButtonClick(data[14])}
        >
          {data[14].head}
        </button>

        <button
          className="btn1 tenth-color"
          onClick={() => handleButtonClick(data[15])}
        >
          {data[15].head}
        </button>
        <button
          className="btn3 nineth-color"
          onClick={() => handleButtonClick(data[16])}
        >
          {data[16].head}
        </button>
        <button
          className="btn2 eighth-color"
          onClick={() => handleButtonClick(data[17])}
        >
          {data[17].head}
        </button>
        <button
          className="btn3 seventh-color"
          onClick={() => handleButtonClick(data[18])}
        >
          {data[18].head}
        </button>
        <button
          className="btn1 sixth-color"
          onClick={() => handleButtonClick(data[19])}
        >
          {data[19].head}
        </button>

        <button
          className="btn3 ist-color"
          onClick={() => handleButtonClick(data[20])}
        >
          {data[20].head}
        </button>
        <button
          className="btn2 second-color"
          onClick={() => handleButtonClick(data[21])}
        >
          {data[21].head}
        </button>
        <button
          className="btn3 third-color"
          onClick={() => handleButtonClick(data[22])}
        >
          {data[22].head}
        </button>
        <button
          className="btn1 forth-color"
          onClick={() => handleButtonClick(data[23])}
        >
          {data[23].head}
        </button>
        <button
          className="btn2 fifth-color"
          onClick={() => handleButtonClick(data[24])}
        >
          {data[24].head}
        </button>
        <button
          className="btn3 nineth-color"
          onClick={() => handleButtonClick(data[25])}
        >
          {data[25].head}
        </button>
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="button-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="wesay-modal">
            <div className="left">
              <img src="/assets/images/wesaymodal.webp" alt="" />
            </div>
            <div className="right">
              <h3 className="fw-bold text-montserrat">{modalContent.head}</h3>
              <p className="medium fw-normal text-montserrat">
                {modalContent.istpara}
              </p>
              <div>
                <p className="medium sub-head fw-bold text-montserrat">
                  {modalContent.subhead}
                </p>
                <p className="medium fw-normal text-montserrat">
                  {modalContent.secondpara}
                </p>
                {/* <Button
                  className="primary-btn small-btn fw-medium rounded demo-btn"
                  text="Read More"
                  width="172px"
                  height="46px"
                /> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default Wesay;

const data = [
  {
    head: "AI Video Creator",
    istpara:
      "Simplify the difficult process of video generation using the AI Video Creator by Mister AI. This feature simplifies the process of video creation by simply having the user enter basic prompts and uses AI driven data algorithms to generate video clips according to the need. This feature can be used for various purposes and streamlines the long process of video generation. ",
    subhead: "GRAPHICS IN SECONDS",
    secondpara:
      "AI Video Generator brings a big leverage of time efficiency for the masses who always felt a hurdle in the face of complex video editing and filming process. Whether it is for personal use, marketing use, content creation or other uses, AI video creator has provided hassle-free and instant solutions without compromising on creativity and standards.",
    id: 0,
  },
  {
    head: "AI DIGIBILL",
    istpara:
      "AI Digibill is a feature of Mister AI that uses Artificial Intelligence in the generation of invoices. It is designed for entrepreneurs, freelancers, and small businesses. This AI Invoice Generator streamlines the invoicing process with accuracy and professionalism. With AI Digibill, Mister AI brings ease to billing matters by save time handling all the hassle work.",
    subhead: "EFFICIENT INVOICING, SIMPLIFIED",
    secondpara:
      "AI DigiBill makes invoicing as easy as it gets. Users input transaction details, and the AI Invoice Generator creates accurate, professional invoices tailored to specific business needs at any time and place. No more manual calculations or formatting hassles – it's invoicing made simple and efficient.",
    id: 1,
  },
  {
    head: "AI CHAT SPARKS",
    istpara:
      "AI Chat Sparks by Mister AI is a solution for businesses to streamline their customer engagement. Immerse your business in the future of customer interactions with automated chatbots. It redefines engagement through intelligent and responsive virtual assistants. These Al Chatbots stand ready to engage with users in real-time, answering queries, providing information, and revolutionizing customer support.",
    subhead: "INSTANT SOLUTIONS AT YOUR FINGERTIPS",
    secondpara:
      "Al Chat Sparks brings real-time interaction to the forefront of customer engagement. Whether it's answering queries or providing on-the-spot information, these intelligent virtual assistants ensure that users receive instant solutions at their fingertips. It's about creating seamless, responsive experiences that align with the pace of the digital world.",
    id: 2,
  },
  {
    head: "AI WEB BUILD",
    istpara:
      "Experience ease in the process of website creation with AI Web Build, Mister AI's revolutionary feature that allows users to create dynamic and responsive web designs. By integrating intuitive design suggestions with advanced AI capabilities, AI Web Build delivers visually striking and functioning websites for businesses, users, products and other purposes.",
    subhead: "GUIDED CREATIVITY FOR EVERYONE",
    secondpara:
      "AI Web Build serves as your virtual design companion, offering intuitive guidance throughout the creative process. Whether you're a seasoned developer or new to web design, this tool ensures an easy and smooth experience. With a vast target audience, AI Web Build offers the opportunity for everyone to build a website for whatever needs they may require it for.",
    id: 3,
  },
  {
    head: "AI CODER",
    istpara:
      "AI Coder by Mister AI is a feature that helps generate codes. It serves as your virtual coding assistant, providing intelligent suggestions and autocomplete features. The essence of AI Coder lies in its ability to streamline the coding workflow. By minimizing repetitive tasks and optimizing complex algorithms. This allows users of all skill levels to save time on their work and bring ease to the process.",
    subhead: "YOUR VIRTUAL CODING ASSISTANT",
    secondpara:
      "In the world of coding, intelligence takes center stage with Mister AI's AI Coder. This groundbreaking tool is engineered to turbocharge coding processes, empowering developers with an array of intelligent features that redefine the coding workflow. Whether you're a novice learning the ropes or a seasoned developer striving for efficiency, the AI Coding Tool elevates code quality and expedites development cycles.",
    id: 4,
  },
  {
    head: "AI DEPLOYER",
    istpara:
      "AI Deployer by Mister AI is a deployment tool that allows the deployment of any finished project and product. It is created to streamline deployment processes by optimizing resource allocation, automating workflows, and ensuring efficient and secure application execution. Tailored for businesses managing complex IT infrastructures, AI Deployer enhances scalability, reduces downtime, and improves operational efficiency.",
    subhead: "YOUR STRATEGIC DEPLOYMENT ALLY",
    secondpara:
      "AI Deployer becomes a strategic ally, empowering organizations to navigate deployment intricacies seamlessly. Assisting in time-consuming activities and serving to streamline complex processes, AI Deployer becomes an integral feature in the workflow processes of companies ranging from giant corporates to small office firms.  This renders unwarranted attention to tasks needing it.",
    id: 5,
  },
  {
    head: "AI TASK ASSIST",
    istpara:
      "AI Task Assist is like a virtual assistant that helps with various forms of tasks, allowing them to be handled more quickly and efficiently, giving leverage in focus and time. At the forefront of productivity, AI Task Assist by Mister AI is a feature to revolutionize task management. Like a virtual companion, it helps in providing guidance along the world flow by combining AI capabilities to the structure of workflow processes.",
    subhead: "YOUR VIRTUAL PRODUCTIVITY COMPANION",
    secondpara:
      "Whether it's Simplifying the Daily Workflow with charts and To-Do lists, or Information retrieval from archives of data, AI Task Assist ensures you get uninterrupted AI support. Along with it, AI Task Assist helps in Appointment Scheduling as well as giving personalized recommendations on various tasks and data to further streamline the way work processes are handled.",
    id: 6,
  },
  {
    head: "AI SPORTS ORACLE",
    istpara:
      "AI Sports Oracle is a feature of Mister AI that works as a sports predictive tool. By utilizing the power of machine learning algorithms, predictive modeling, and historical data analysis, this tool aims to deliver precise forecasts for sports outcomes. This offers a glimpse into the future of sports outcomes, providing users with insights derived from comprehensive data.",
    subhead: "YOUR WINDOW INTO THE FUTURE OF SPORTS",
    secondpara:
      "With a focus on deciphering patterns and trends, Mister AI's AI Sports Predictor empowers sports enthusiasts, analysts, and betting enthusiasts alike with data-driven insights that elevate the anticipation and enjoyment of sporting events.  This innovative tool promises to revolutionize the way we understand and engage with sports events.",
    id: 7,
  },
  {
    head: "AI VIGIL CAM",
    istpara:
      "AI Vigil Cam is an AI technology that is integrated into security cameras. This allows for security cameras to serve as threat detection cameras. This is a groundbreaking innovation by Mister AI.  By integrating technologies such as computer vision, deep learning, and real-time image analysis, these cameras are engineered to identify potential threats and security breaches before they are likely to occur.",
    subhead: "A VISION OF SAFETY",
    secondpara:
      "AI Threat Detection Cameras offer a visionary approach to security, providing users with advanced capabilities derived from sophisticated data analysis. Deployable both in public spaces and corporate environments, Mister AI's AI Threat Detection Cameras are committed to enhancing situational awareness by redefining the landscape of security surveillance, ensuring a safer and more secure future for all.",
    id: 8,
  },
  {
    head: "AI QUAKE WATCH",
    istpara:
      "With the efficient and prompt use of seismic data, Mister AI’s AI Quake Watch acts as an earthquake detection technology. By harnessing sensor data, machine learning algorithms, and predictive modeling, this innovative tool is designed to offer early detection of seismic activities, enabling communities to prepare and respond swiftly to mitigate the impact of earthquakes.",
    subhead: "A NEW HORIZON IN EARTHQUAKE DETECTION",
    secondpara:
      "Through the analysis of geological data patterns, Mister AI aims to contribute to global efforts in earthquake monitoring and disaster resilience, fostering a safer and more resilient world. With uses ranging from researching to proactive disaster measures, this visionary solution promises to redefine awareness and monitoring, empowering swift response capabilities to safeguard lives and infrastructure.",
    id: 9,
  },
  {
    head: "AI TALK WISE",
    istpara:
      "This AI Voice Assistant is a tool designed to not only respond intelligently to user commands but also understand context, emotions, and adapt to individual preferences. By leveraging natural language processing, machine learning, and sentiment analysis, AI Talk Wise aims to deliver a personalized and conversational interaction with AI, elevating the user experience.",
    subhead: "A REVOLUTION IN VOICE TECHNOLOGY",
    secondpara:
      "Whether you're seeking assistance with daily tasks or engaging in meaningful conversations, this visionary solution promises to redefine the way we interact with technology. With its ability to understand context, emotions, and adaptability to individual preferences, the AI Voice Assistant sets a new standard for voice assistance, empowering users to accomplish tasks with ease and efficiency.",
    id: 10,
  },
  {
    head: "AI AUTOGRAFIQ",
    istpara:
      "AI Autografiq serves as Mister AI's AI Handwriting/Biometric tool. Utilizing advanced pattern recognition, neural networks, and biometric authentication techniques, this innovative tool is engineered to accurately identify individuals based on their handwriting or biometric features. Its applications range from secure access control systems to forensic analysis.",
    subhead: "A PIONEERING APPROACH TO IDENTITY VERIFICATION",
    secondpara:
      "Mister AI's AI Handwriting/Biometric tool aims to provide a robust solution for identity verification, bolstering security across various domains. The tool is handy in analyzing and interpreting various handwriting samples and comparing them to other pools of data. This AI Handwriting/Biometric tool has the potential to set a new standard for identity verification, empowering organizations to safeguard sensitive information and assets.",
    id: 11,
  },
  {
    head: "AI STOCKIFY",
    istpara:
      "AI Stockify is an AI tool that provides insights into the stock markets for any given country. It is a prediction tool by Mister AI that helps users make educated decisions. By analyzing market data, financial indicators, and macroeconomic trends, this tool provides predictions and insights into the movement of stocks, allowing users to make informed decisions and achieve their investment goals.",
    subhead: "A VISIONARY APPROACH TO STOCK PREDICTION",
    secondpara:
      "The AI Stock Prediction tool transcends conventional market analysis, offering unparalleled insights into stock movements by harnessing the power of machine learning algorithms and predictive analytics. Mister AI aims to empower investors and traders with data-driven decisions in the dynamic world of financial markets by leveraging AI technologies such as ML and other predictive algorithms.",
    id: 12,
  },
  {
    head: "AI INFECTRACE",
    istpara:
      "Amidst global health challenges in recent times, Mister AI leads the charge in developing an AI Pandemic Detection tool. Leveraging machine learning models, data from health sources, and real-time monitoring, this tool aims to detect early signs of potential pandemics by analyzing patterns in disease spread and identifying any emerging threats. Mister AI seeks to contribute to a global health drive.",
    subhead: "A VITAL TOOL FOR GLOBAL HEALTH",
    secondpara:
      "The AI Pandemic Detection tool represents a critical advancement in global health surveillance. This visionary solution promises to revolutionize pandemic preparedness and response. Whether identifying emerging threats or providing insights for preventive measures, Mister AI's commitment to global health is unwavering, ensuring a safer and healthier future for all.",
    id: 13,
  },
  {
    head: "AI MEDICO AIDE",
    istpara:
      "AI Medico Aide is the feature of Mister AI that acts as a medical expert assistant to professionals. This tool supports the medical professionals in diagnosis and surgical procedures. By integrating medical knowledge databases, image recognition, and machine learning, it helps to deliver accurate diagnostics, treatment recommendations, and surgical planning assistance, providing ease in the hectic life of medical experts.",
    subhead: "A NEW ERA OF MEDICAL ASSISTANCE",
    secondpara:
      "With a focus on enhancing medical decision-making, improving patient outcomes, and alleviating the workload on healthcare professionals, Mister AI's vision for the future of healthcare is transformative. This visionary tool empowers medical professionals to enhance healthcare delivery and ensures that every patient receives the highest quality of care. All while, healthcare professionals benefit from streamlined workflows and improved decision-making capabilities.",
    id: 14,
  },
  {
    head: "AI SCULPTERA",
    istpara:
      "AI Sculptera by Mister AI is an AI tool that is designed to assist in the generation of 3D sculpture creation. By integrating generative algorithms, artistic style analysis, and 3D modeling, AI Sculptera aims to empower artists in crafting unique and visually captivating sculptures. Mister AI's commitment to amplifying artistic expression is a collaboration between human creativity and artificial intelligence.",
    subhead: "TRANSFORMING ARTISTIC VISIONS INTO REALITY",
    secondpara:
      "Mister AI envisions a platform where artistic expression is elevated through the collaboration of human creativity and artificial intelligence, fostering new avenues for creative exploration. Whether crafting intricate details or experimenting with abstract forms, the AI Sculpture Design tool offers a transformative approach to sculpture design with generative algorithms and artistic style analysis.",
    id: 15,
  },
  {
    head: "AI TUTOR",
    istpara:
      "Mister AI presents a step to revolutionize education through the introduction of an AI Teaching Assistant in the form of an AI Tutor. Imbued with the power of natural language processing, adaptive learning algorithms, and interactive engagement, this educational tool aims to empower educators to deliver personalized learning experiences while also assisting them with their paperwork, planning, and scheduling procedures.",
    subhead: "TRANSFORMING THE CLASSROOM DYNAMIC",
    secondpara:
      "The AI Teaching Assistant represents a groundbreaking advancement in educational technology, reshaping the traditional classroom dynamic. From facilitating lesson planning to addressing individual student needs, the AI Teaching Assistant strives to enhance the efficiency and effectiveness of the teaching process. This tool enables educators to tailor lessons to meet the unique needs and learning styles of each student.",
    id: 16,
  },
  {
    head: "AI SEA WATCH",
    istpara:
      "In the maritime domain, Mister AI took a step toward the development of a Pirates Detection system. This was aimed at revolutionizing maritime security. AI Sea Watch Integrates satellite imagery, machine learning, and anomaly detection. By using these technologies, it helps to identify and track potential pirate activities in maritime zones. By analyzing patterns of movement and behavior, This tool can be a giant security leverage for governments and organizations.",
    subhead: "A VITAL TOOL FOR MARITIME SAFETY",
    secondpara:
      "Mister AI endeavors to contribute to maritime security, safeguarding vessels from piracy threats in vulnerable regions. The AI Pirates Detection system represents a critical advancement in maritime security, offering unparalleled capabilities in threat detection and tracking. This visionary solution empowers maritime authorities to identify and respond swiftly to potential pirate activities.",
    id: 17,
  },
  {
    head: "AI PITCH PRO",
    istpara:
      "Mister AI went along with the idea of creating a professional AI Business Pitching tool. AI pitch Pro targets and changes the approach for business presentations. Harnessing voice recognition, natural language processing, and persuasive communication analysis, this tool aims to aid individuals and businesses in refining their business pitches by helping them in the creation of successful business presentations.",
    subhead: "EMPOWERING ENTREPRENEURIAL SUCCESS",
    secondpara:
      "The AI Business Pitching tool represents an advancement in supporting entrepreneurial endeavors. By offering feedback on tone, clarity, and persuasive elements, Mister AI envisions a platform where entrepreneurs can sharpen their communication skills, enabling them to craft compelling and persuasive pitches. With Mister AI's commitment, entrepreneurs can confidently deliver impactful presentations.",
    id: 18,
  },
  {
    head: "AI ASTRO STAR",
    istpara:
      "AI Astro Star by Mister AI is a horoscope tool that makes its star-based assumptions and predictions using AI technology. Offering customized predictions, AI Astro Star combines astrological insights with individual user data and machine learning. This helps to deliver accurate and tailored horoscopes. Mister AI envisions a unique and interactive astrological experience using AI as a focal point.",
    subhead: "UNLOCKING THE MYSTERIES OF THE COSMOS",
    secondpara:
      "The AI Horoscope tool offers a glimpse into the mysteries of the cosmos, providing users with personalized insights derived from celestial alignments and individual user data. With Mister AI's commitment to providing tailored and customized horoscopes, users can embark on a journey of self-discovery and personal growth guided by the signs of the stars.",
    id: 19,
  },
  {
    head: "AI GUIDER",
    istpara:
      "AI Guider is a geospatial mapping feature of Mister AI that uses AI technology to deliver various mapping solutions. Integrated with satellite imagery, real-time data from analytics, and machine learning algorithms, this tool aims to provide dynamic and intelligent mapping solutions with accuracy and vigilance. From individual use to industrial prospects, AI Guider blends AI with traditional GPS technology.",
    subhead: "A NEW ERA OF DIGITAL MAPPING",
    secondpara:
      "The AI Maps tool represents a significant advancement in geospatial intelligence, offering unparalleled capabilities in mapping and navigation. Whether for urban planning, disaster response, or navigation, Mister AI envisions a platform that enhances the accuracy and utility of digital maps through AI technologies to provide users with dynamic and intelligent mapping solutions for a variety of applications.",
    id: 20,
  },
  {
    head: "AI GAME MENTOR",
    istpara:
      "The essence of a coach is vital to every emerging and rising player in order to chart out a direction toward success. With AI Game Mentor, Mister AI provides a technological solution to its traditional counterpart. AI Game Mentor serves to act as a personalized coach for players from various sports. This tool is designed to assist athletes in optimizing their training regimens and physical performance outputs.",
    subhead: "ELEVATING ATHLETIC POTENTIAL THROUGH DATA",
    secondpara:
      "The AI Game Mentor represents a significant advancement in athletic training, offering athletes invaluable insights derived from biomechanical data and performance analytics. By providing personalized training recommendations tailored to individual needs, this visionary tool empowers athletes to maximize their potential and minimize the risk of injuries.",
    id: 21,
  },
  {
    head: "AI TRANSLATOR",
    istpara:
      "AI Translator by Mister AI uses AI language models to translate not only the literal aspects of any language but also the verbal connotations, tones, and cues. This improves upon simple translation tools and provides an upgraded version of Translation mechanics. Experience smoother meetings and conversations on an international stage with the introduction of AI Translator.",
    subhead: "BRIDGING THE LANGUAGE DIVIDE",
    secondpara:
      "AI Translator emerges as a transformative solution, bridging the language gap and fostering cross-cultural communication. With its ability to provide real-time translations during meetings, conferences, and discussions, this visionary tool ensures that language differences no longer pose a challenge to effective communication.  AI Translator makes meetings smoother and more productive for all participants.",
    id: 22,
  },
  {
    head: "AI TUNE COMPOSER",
    istpara:
      "Compose your songs effortlessly with AI Tune Composer. Mister AI brings forward a new way to approach one’s musical endeavors with the AI Tune Composer. This revolutionary tool allows users to synchronize melodies along with their song scripts. With the facility to operate and function in multiple languages, the AI Tune Composer helps compose resonating tunes to the needs of the user.",
    subhead: "UNLEASH YOUR CREATIVITY",
    secondpara:
      "AI Tune Composer empowers music enthusiasts and songwriters to unleash their creativity without the need for musical expertise. From pop to rock, ballads to hip-hop, AI Tune Composer offers endless possibilities for musical expression. With simple prompts and ease of use, Mister AI's innovative technology brings forward the ease of composing music, which has never been easier or more accessible.",
    id: 23,
  },
  {
    head: "AI LOOK UP",
    istpara:
      "AI Look Up is a lens powered by AI data algorithms that allow you to scan and track the product on the web with its full details, counterparts, origins, and availability. Utilizing the camera, AI Lookup enables users to recognize and interact with objects and text in the real world. Whether you're curious about the name of a plant, the brand of a product, or the translation of a foreign text, AI Lookup provides instant answers at your fingertips.",
    subhead: "UNLEASH YOUR CURIOSITY",
    secondpara:
      "AI Lookup opens up a world of possibilities, allowing users to explore their surroundings in a whole new way. With Mister AI's innovative technology, the world becomes your playground for discovery and exploration. Whether you're traveling, shopping, or simply exploring your surroundings, AI Lookup enhances your experience by providing valuable information in real time.",
    id: 24,
  },
  {
    head: "AI VOCALS",
    istpara:
      "AI Vocals revolutionize communication with advanced voice-to-text and text-to-voice capabilities. They accurately transcribe spoken words into text and convert written text into natural-sounding speech, making them invaluable for tasks like transcribing meetings, creating audio content, and improving accessibility. With customization options and seamless integration.",
    subhead: "VOICE YOUR THOUGHTS, TEXT YOUR WORDS!",
    secondpara:
      "AI Vocals offer personalized experiences and automate voice-related tasks, while ethical considerations underscore the importance of responsible use in the digital landscape.",
    id: 25,
  },
];
