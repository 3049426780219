import React from 'react';
import "../aiSummaros/roboSection.scss";
import robo from "../../assets/images/aiSummaros/roboPic.webp";
import glow from "../../assets/images/aiSummaros/roboGlow.webp";

const RoboSection = ({h1, span, p}) => {
  return (
    <div className='roboSectionWrapper'>
      <img className='glow' src={glow} alt="" />
      <div className="inner">
        <div className="leftArea">
        <h1 className="text-montserrat fw-bold">
          {h1}
            <span className="text-main">{span}</span>
          </h1>
          <p className="medium text-montserrat fw-normal mt-2">
       {p}
          </p>
          <div className="totalNum d-flex gap-5">
            <div className="task">
                <h1 className='text-montserrat fw-medium'>1,000+</h1>
                <h6 className='text-montserrat fw-medium '>Task Completed</h6>
            </div>

            <div className="task">
                <h1 className='text-montserrat fw-medium'>1,000+</h1>
                <h6 className='text-montserrat fw-medium'>Happy Customers</h6>
            </div>


            <div className="task">
                <h1 className='text-montserrat fw-medium'>75+</h1>
                <h6 className='text-montserrat fw-medium'>Project Automated</h6>
            </div>
          </div>
        </div>
        <div className="rightArea">
            <img className='robo' src={robo} alt="" />
        </div>
        </div>

    </div>
  )
}

export default RoboSection;