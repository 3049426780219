import React, { useEffect, useRef, useState } from "react";
import "../aiSummaros/helpYou.scss";
import grayLines from "../../../src/assets/images/aiSummaros/grayLines.png";
import leftGlow from "../../assets/images/aiSummaros/top-left-glow.webp";
import rightGlow from "../../assets/images/aiSummaros/top-right-glow.webp";
import Typewriter from "typewriter-effect";
const HelpYou = ({ typewriterMessage }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // const textName="Generate";
  const [show, setShow] = useState(true);
  const handleClick = () => {
    setShow(false);
  };
  const ref = useRef();
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(true);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Show input field if window width is less than 700 pixels
    if (windowWidth < 700) {
      setShow(false);
    }
  }, [windowWidth]);

  return (
    <>
      <div className="helpWrapper">
        <img className="leftGlow" src={leftGlow} alt="" />
        <img className="rightGlow" src={rightGlow} alt="" />
        <div className="inner">
          <div className="midDiv">
            <h6 className="text-jakarta fw-semibold text-black pt-5 h-center">
              How can I help you?
            </h6>
            <img className="grayLines" src={grayLines} alt="" />

            <div
              className="inputBox gap-2"
              onClick={() => handleClick()}
              ref={ref}
            >
              <Typewriter
                options={{
                  strings: typewriterMessage,
                  autoStart: true,
                  loop: true,
                }}
              />
              {/* 
              {windowWidth < 700 ? (
                <input type="text" placeholder="Type to Generate ..." />
              ) : windowWidth > 700 && !show ? (
                <input type="text" />
              ) : (
                ""
              )} */}

              <button>Generate</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpYou;
