import "./button.scss";
const Button = ({
  text,
  className,
  minHeight,
  maxHeight,
  minWidth,
  height,
  width,
  onClick,
}) => {
  const buttonStyle = {
    minHeight: minHeight,
    maxHeight: maxHeight,
    minWidth: minWidth,
    height: height,
    width: width,
  };

  return (
    <button className={className} style={buttonStyle} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
