import React from "react";
import "../aiSummaros/benefits.scss";
import Heading from "../heading/heading";
import BenefitCard from "./benefitCard";

import benefitcard1Img from "../../assets/images/aiSummaros/benefits/timeCard.svg";
import benefitcard2Img from "../../assets/images/aiSummaros/benefits/improvedCard.svg";
import benefitcard3Img from "../../assets/images/aiSummaros/benefits/enhanceCard.svg";
import benefitcard4Img from "../../assets/images/aiSummaros/benefits/learningCard.svg";
import benefitcard5Img from "../../assets/images/aiSummaros/benefits/languageCard.svg";
import benefitcard6Img from "../../assets/images/aiSummaros/benefits/engagementCard.svg";

import backLayer from "../../assets/images/aiSummaros/benefits/yellow-backLine.webp";
import { useLocation } from "react-router-dom";

const Benefits = () => {
  const location = useLocation();

  const DataSetBenefit = [
    {
      AiSummaros: [
        {
          id: 1,
          img: benefitcard1Img,
          head: "Time Efficiency",
          para: "Save time and effort by quickly generating concise summaries of lengthy texts, articles, or documents, enabling you to focus on other tasks and priorities.",
        },
        {
          id: 2,
          img: benefitcard2Img,
          head: "Enhanced Productivity",
          para: "Increase productivity and information absorption by condensing complex or voluminous content into digestible summaries that are easy to understand and retain.",
        },

        {
          id: 3,
          img: benefitcard3Img,
          head: "Customized Content",
          para: "Tailor your summaries to suit your specific needs or preferences, ensuring that you focus on the most relevant information for your purposes.",
        },

        {
          id: 4,
          img: benefitcard4Img,
          head: "Clarity and Conciseness",
          para: "Communicate key points and insights clearly and concisely with professionally generated summaries that capture the essence of the original content.",
        },

        {
          id: 5,
          img: benefitcard5Img,
          head: "Improved Decision-Making",
          para: "Make informed decisions faster by quickly reviewing summarized information, enabling you to identify trends, patterns, and important details at a glance.",
        },

        {
          id: 6,
          img: benefitcard6Img,
          head: "Collaboration Facilitation:",
          para: "Share easily with team members by distributing summarized content, allowing for simple communication without extensive readings or lengthy meetings.",
        },
      ],
    },
    {
      AImagify: [
        {
          id: 1,
          img: benefitcard1Img,
          head: "Expressive Creativity",
          para: "Unlock limitless creative possibilities by transforming simple prompts into visually captivating images, regardless of artistic skill or background.",
        },
        {
          id: 2,
          img: benefitcard2Img,
          head: "Rapid Prototyping",
          para: "Quickly visualize ideas and concepts through instant image generation, enabling faster decision-making and iteration in creative projects.",
        },

        {
          id: 3,
          img: benefitcard3Img,
          head: "Time and Cost Efficiency",
          para: "Save time and resources by automating the image creation process, eliminating the need for manual design work or costly graphic assets.",
        },

        {
          id: 4,
          img: benefitcard4Img,
          head: "Seamless Integration",
          para: "Easily incorporate generated images into various projects and platforms, thanks to compatibility with common file formats and design software.",
        },

        {
          id: 5,
          img: benefitcard5Img,
          head: "Inclusivity",
          para: " Provides opportunities for diverse individuals to participate in visual expressions, fostering inclusivity and accessibility regardless of traditional artistic skills or physical limitations. ",
        },

        {
          id: 6,
          img: benefitcard6Img,
          head: "Risk Reduction",
          para: "Minimize the risk of creative stagnation by leveraging AI Imagify to explore diverse styles, concepts, and compositions, allowing you to experiment and innovate.  a Bigger Part of the Future ",
        },
      ],
    },
    {
      ContentPro: [
        {
          id: 1,
          img: benefitcard1Img,
          head: "Effortless Content Creation",
          para: "Simplify the process of creating blog posts with our AI blog generator, saving time and resources while maintaining quality and relevance.",
        },
        {
          id: 2,
          img: benefitcard2Img,
          head: "Cost-Effective Solution",
          para: "Choose between our free and paid options to suit your budget and needs, with the flexibility to upgrade as your requirements evolve.",
        },

        {
          id: 3,
          img: benefitcard3Img,
          head: "Enhanced SEO Performance",
          para: "Improve your blog's search engine rankings and visibility with AI-generated SEO tags and keyword suggestions, maximizing organic traffic and audience reach.",
        },

        {
          id: 4,
          img: benefitcard4Img,
          head: "Professional-Quality Content",
          para: "Generate well-structured and engaging blog posts that resonate with your audience, thanks to customizable content sections and intelligent image integration.",
        },

        {
          id: 5,
          img: benefitcard5Img,
          head: "Time Efficiency",
          para: "Streamline your content creation process and say goodbye to your writer's block forever.",
        },
        {
          id: 6,
          img: benefitcard6Img,
          head: "Scalability",
          para: " Scale your content effortlessly to meet growing demands or fluctuations in audience engagement, ensuring that your blog remains a reliable source of valuable information.",
        },
      ],
    },

    {
      AiQuiz: [
        {
          id: 1,
          img: benefitcard1Img,
          head: " Knowledge Enhancement",
          para: "Expand your knowledge and expertise across a variety of topics and subjects through engaging quizzes that stimulate learning and retention.",
        },
        {
          id: 2,
          img: benefitcard2Img,
          head: "Personalized Learning Experience",
          para: "Receive personalized recommendations and feedback based on your quiz performance and preferences, enabling targeted learning and skill development.",
        },

        {
          id: 3,
          img: benefitcard3Img,
          head: "Motivation and Engagement",
          para: "Stay motivated and engaged with the opportunity to earn rewards and incentives for your quiz achievements, creating a fun and rewarding learning experience.",
        },

        {
          id: 4,
          img: benefitcard4Img,
          head: "Flexibility and Accessibility",
          para: "Access quizzes anytime, anywhere, with our user-friendly platform that supports multiple devices and allows for seamless learning on the go.",
        },

        {
          id: 5,
          img: benefitcard5Img,
          head: "Skill Development",
          para: "Sharpen your critical thinking, problem-solving, and decision-making skills through challenging quizzes that encourage active learning and participation. ",
        },

        {
          id: 6,
          img: benefitcard6Img,
          head: "Diverse Content Selection",
          para: "Gain exposure to a wide range of topics and subjects through our extensive library of quizzes with various interests and niches. ",
        },
      ],
    },

    {
      CvMaker: [
        {
          id: 1,
          img: benefitcard1Img,
          head: "Time-Saving Solution",
          para: "Say goodbye to hours spent formatting and tweaking your resume. Our AI CV Maker streamlines the process, allowing you to generate a professional-grade resume in minutes",
        },
        {
          id: 2,
          img: benefitcard2Img,
          head: "Enhanced Professionalism",
          para: "Stand out from the crowd with a visually appealing and well-organized resume that highlights your skills and experience effectively.",
        },

        {
          id: 3,
          img: benefitcard3Img,
          head: "Tailored Recommendations",
          para: "Receive personalized recommendations based on industry best practices and AI-driven analysis, ensuring your resume is optimized for success.",
        },

        {
          id: 4,
          img: benefitcard4Img,
          head: "Increased Confidence",
          para: "Feel confident knowing that your resume reflects your qualifications and accomplishments accurately, giving you a competitive edge in your job search.",
        },

        {
          id: 5,
          img: benefitcard5Img,
          head: " Accessible 24/7",
          para: "Our user-friendly platform is accessible anytime, anywhere, allowing you to create or update your resume on the go.",
        },

        {
          id: 6,
          img: benefitcard6Img,
          head: "Cost-Effective Solutions",
          para: "Part of a bigger AI system, AI CV Maker offers efficient and affordable solutions.",
        },
      ],
    },
  ];

  const renderFeatureCards = () => {
    const pathname = location.pathname;

    let classNamesToPass = [];

    if (pathname === "/ai-summaros") {
      classNamesToPass = [1, 3, 5]; // IDs for AiSummaros
    } else if (pathname === "/ai-imagify") {
      classNamesToPass = [1, 3, 5]; // IDs for AiImagify
    } else if (pathname === "/content-pro") {
      classNamesToPass = [1, 3, 5]; // IDs for AiContent
    } else if (pathname === "/ai-quiz") {
      classNamesToPass = [1, 3, 5]; // IDs for AiQuiz
    } else if (pathname === "/cv-maker") {
      classNamesToPass = [1, 3, 5]; // IDs for cvMaker
    }

    const shouldHaveClass = (id) => classNamesToPass.includes(id);

    if (pathname === "/ai-summaros") {
      return DataSetBenefit[0].AiSummaros.map((item) => (
        <BenefitCard
          key={item.id}
          imgs={item.img}
          heading={item.head}
          paragraph={item.para}
          border1={shouldHaveClass(item.id) ? "border1" : ""}
          bBottom="border-bottom"
        />
      ));
    } else if (pathname === "/ai-imagify") {
      return DataSetBenefit[1].AImagify.map((item) => (
        <BenefitCard
          key={item.id}
          imgs={item.img}
          heading={item.head}
          paragraph={item.para}
          border1={shouldHaveClass(item.id) ? "border1" : ""}
          bBottom="border-bottom"
        />
      ));
    } else if (pathname === "/content-pro") {
      return DataSetBenefit[2].ContentPro.map((item) => (
        <BenefitCard
          key={item.id}
          imgs={item.img}
          heading={item.head}
          paragraph={item.para}
          border1={shouldHaveClass(item.id) ? "border1" : ""}
          bBottom="border-bottom"
        />
      ));
    } else if (pathname === "/ai-quiz") {
      return DataSetBenefit[3].AiQuiz.map((item) => (
        <BenefitCard
          key={item.id}
          imgs={item.img}
          heading={item.head}
          paragraph={item.para}
          border1={shouldHaveClass(item.id) ? "border1" : ""}
          bBottom="border-bottom"
        />
      ));
    } else if (pathname === "/cv-maker") {
      return DataSetBenefit[4].CvMaker.map((item) => (
        <BenefitCard
          key={item.id}
          imgs={item.img}
          heading={item.head}
          paragraph={item.para}
          border1={shouldHaveClass(item.id) ? "border1" : ""}
          bBottom="border-bottom"
        />
      ));
    } else {
      return null;
    }
  };

  return (
    <div className="benefitWrapper">
      <img className="backLayer" src={backLayer} alt="" />
      <div className="centerDiv">
        {renderFeatureCards()}
        {/* <BenefitCard
          imgs={benefitcard1Img}
          name="Time Saving"
          paragraph="Say goodbye to hours spent formatting and tweaking your resume. Our AI CV Maker streamlines the process, allowing you to generate a professional-resume"
          border1="border1"
          bBottom="border-bottom"
        />
        <BenefitCard
          imgs={benefitcard2Img}
          name="Improved comprehension"
          paragraph="Summarized content can help users understand complex topics more easily by highlighting the key points and main ideas."
          bBottom="border-bottom"
        />
        <BenefitCard
          imgs={benefitcard3Img}
          name="Enhanced productivity"
          paragraph="Professionals and students can efficiently process large amounts of information, helping them make informed decisions or prepare for exams."
          border1="border1"
          bBottom="border-bottom"
        />
        <BenefitCard
          imgs={benefitcard4Img}
          name="Improved comprehension"
          paragraph="Summarized content can help users understand complex topics more easily by highlighting the key points and main ideas."
          bBottom="border-bottom"
        />
        <BenefitCard
          imgs={benefitcard5Img}
          name="Language support"
          paragraph="Users can benefit from summarization services in multiple languages, improving accessibility to information across different regions."
          border1="border1"
        />
        <BenefitCard
          imgs={benefitcard6Img}
          name="Increased engagement"
          paragraph="Summarized content is more likely to be read and shared, leading to increased engagement with the original content."
        /> */}
      </div>
    </div>
  );
};

export default Benefits;
