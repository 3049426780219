import React from "react";
import "../aiSummaros/summarizeContent.scss";


const SummarizeContent = (props) => {
  return (
    <>
      <div className="summarizeContentWrapper">
        <div className="inner">
          <img className="blackBorder" src={props.imgs} alt="" />
        </div>
      </div>
    </>
  );
};

export default SummarizeContent;
