import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/footer";
import Navbar from "./components/navbar/navbar";
import Home from "./components/home/home";
import TermCondition from "./components/termCondition/termCondition";
import Blogpage from "./components/blogpage/blogpage";
import Privacy from "./components/privacyPolicy/privacy";
import Pricing from "./components/pricing/pricing";
import AiSummaros from "./components/aiSummaros/aiSummaros";
import Demo from "./components/demo/demo";
import AiImagify from "./components/aiImagify/aiImagify";
import ContentPro from "./components/contentPro/contentPro";
import AiQuiz from "./components/aiQuiz/aiQuiz";
import CvMaker from "./components/cvMaker/cvMaker";

function App() {
  return (
    <div className="App">
      <>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-&-conditions" element={<TermCondition />} />
          <Route path="/blog" element={<Blogpage />} />
          <Route path="/privacy-policy" element={<Privacy />} />

          <Route path="/pricing" element={<Pricing />} />
          <Route path="/ai-summaros" element={<AiSummaros />}></Route>
          <Route path="/demo" element={<Demo />}></Route>
          <Route path="/ai-imagify" element={<AiImagify />}></Route>
          <Route path="/content-pro" element={<ContentPro />}></Route>
          <Route path="/ai-quiz" element={<AiQuiz />}></Route>
          <Route path="/cv-maker" element={<CvMaker />}></Route>
        </Routes>
        <Footer />
      </>
    </div>
  );
}

export default App;
