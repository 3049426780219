import React, { useState } from "react";
import "./blogs.scss";
function Blogs() {
  const [tab, setTab] = useState("latest");
  const handletab = (active) => {
    setTab(active);
  };
  return (
    <sec className="blogs-sec">
      <div className="inner">
        <div className="head">
          <h4 className="fw-bold text-montserrat">
            Latest <span className="text-main"> Blog </span> &
            <span className="text-main"> News</span>
          </h4>
          <div className="right">
            <span
              className={`fw-medium text-jakarta ${
                tab === "latest" ? "active" : ""
              }`}
              onClick={() => handletab("latest")}
            >
              Latest
            </span>
            <span
              className={`fw-medium text-jakarta ${
                tab === "trending" ? "active" : ""
              }`}
              onClick={() => handletab("trending")}
            >
              Trending
            </span>
            <span
              className={`fw-medium text-jakarta ${
                tab === "news" ? "active" : ""
              }`}
              onClick={() => handletab("news")}
            >
              Todays News
            </span>
          </div>
        </div>
        <div className="blog-cards">
          {data.map((item) => (
            <div className="blog-card">
              <p className="small text-montserrat text-lightblack">
                <span className="text-main">{item.date} </span>[ Automation,{" "}
                {""}
                {item.category} ]
              </p>
              <p className="medium fw-bold text-montserrat">{item.title}</p>
              <img src={item.img1} alt="..." />
            </div>
          ))}
        </div>
      </div>
    </sec>
  );
}

export default Blogs;

const data = [
  {
    date: "Feb 02,2024",
    category: "Latest",
    title: "Explore Artificial Intelligence And Get The Best Out Of It",
    img1: "/assets/images/blogs/1.webp",
  },
  {
    date: "Feb 02,2024",
    category: "Latest",
    title: "Latest Emerging Artificial Intelligence Technology",
    img1: "/assets/images/blogs/2.webp",
  },
  {
    date: "Feb 02,2024",
    category: "Latest",
    title: "Face Recognition App With Deep Machine Learning",
    img1: "/assets/images/blogs/3.webp",
  },
  {
    date: "Feb 02,2024",
    category: "Latest",
    title: "Make Your Ocean Journey Easier With Artificial",
    img1: "/assets/images/blogs/4.webp",
  },
];
