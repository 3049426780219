import React, { useEffect } from "react";
import "../termCondition/termCondition.scss";
import Secondheading from "../secondheading/secondheading";

const TermCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="term-wrapper">
        <Secondheading
          head1="Terms"
          head2="&"
          head3="Conditions"
          para="Read the Terms and Conditions mentioned below clearly at your own accord in accordance to the use of Mister AI. These are deployed to boost and streamline the process of use with Mister AI."
          termColor="text-yellow"
          conditionColor="text-black"
          class="headingWidth"
          addBrTag={false}
        />
        <div className="textArea">
          <h6 className="text-montserrat fw-bold mt-4">Introduction</h6>
          <p className="text-montserrat my-2 fw-medium para">
            These Terms and Conditions govern your use of the Mister AI website
            and services. By accessing or using our website, you agree to be
            bound by these Terms and Conditions. If you do not agree to these
            terms, please do not use our website.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">Use of Our Services</h6>
          <p className="text-montserrat my-2 fw-medium para">
            You may use our services, including the AI Image Generator, AI
            Summarizer, AI Quiz Booster, and other features, for personal or
            commercial purposes. However, you may not use our services for any
            illegal or unauthorized purpose.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">
            Intellectual Property Rights
          </h6>
          <p className="text-montserrat my-2 fw-medium para">
            All content on the Mister AI website, including text, graphics,
            logos, and images, is the property of Mister AI or its licensors and
            is protected by copyright and other intellectual property laws. You
            may not use, copy, or distribute any content from our website
            without our permission.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">
            Disclaimer of Warranties
          </h6>
          <p className="text-montserrat my-2 fw-medium para">
            Our services are provided "as is" and "as available" without any
            warranties of any kind, either express or implied. We do not
            guarantee that our services will be uninterrupted, secure, or
            error-free.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">
            Limitation of Liability
          </h6>
          <p className="text-montserrat my-2 fw-medium para">
            In no event shall Mister AI or its affiliates be liable for any
            indirect, incidental, special, consequential, or punitive damages,
            including but not limited to, loss of profits, data, or goodwill,
            arising out of or in connection with your use of our services.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">Governing Law</h6>
          <p className="text-montserrat my-2 fw-medium para">
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of [Your Country], without regard to its
            conflict of law principles.
          </p>

          <h6 className="text-montserrat fw-bold mt-4">
            Changes to These Terms and Conditions
          </h6>
          <p className="text-montserrat my-2 fw-medium para">
            We reserve the right to update or change these Terms and Conditions
            at any time. Any changes will be effective immediately upon posting
            on this page. Your continued use of our website after any such
            changes constitutes your acceptance of the new Terms and Conditions.
          </p>

          <h5 className="text-montserrat fw-bold">Contact Us</h5>
          <p className="text-montserrat my-2 fw-medium para">
            If you have any questions or concerns about these Terms and
            Conditions, please contact us at
            [contact@misterai.com](mailto:contact@misterai.com).
          </p>
        </div>
      </div>
    </>
  );
};

export default TermCondition;
