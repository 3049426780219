import React from 'react';
import "./iqLevel.scss";
import iqImg from "../../assets/images/aiQuiz/iqLevel.png";

const IqLevel = () => {
  return (
<div className="iqLevelWrapper">
    <div className="inner">
        <img src={iqImg} alt="" />
    </div>
</div>
  )
}

export default IqLevel;