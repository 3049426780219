import React from "react";
import "./pricing.scss";
import Heading from "../heading/heading";
import Button from "../button/button";
function Pricing() {
  return (
    <section className="pricing-sec">
      <Heading maintext="Pricing" subtext="Choose The Price" />
      <div className="inner">
        <div className="pricing-cards">
          <div className="ist">
            <div className="top">
              <h5 className="light-textblack text-montserrat fw-bold">
                Starter
              </h5>
              <span className="light-text text-montserrat fw-normal">
                Get a hang of Mister AI
              </span>
              <p className="large text-lightblack text-montserrat fw-normal">
                Free
              </p>
            </div>
            <div className="center">
              <span className="text-lightblack text-montserrat fw-normal">
                Free limited trials for all features
              </span>
              <div className="features">
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI CV Maker
                    </p>
                  </div>
                  <ul>
                    <li>3 trials</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Imagify
                    </p>
                  </div>
                  <ul>
                    <li>3 trials</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Vocals
                    </p>
                  </div>
                  <ul>
                    <li>3 trials</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Summaros
                    </p>
                  </div>
                  <ul>
                    <li>3 trials</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Quiz Booster
                    </p>
                  </div>
                  <ul>
                    <li>3 trials</li>
                  </ul>
                </div>
              </div>
            </div>

            <Button
              className="primary-btn small-btn fw-medium rounded"
              text="Sign Up, It’s Free"
              width="100%"
              height="48.47px"
            />
          </div>
          <div className="ist">
            <div className="top">
              <h5 className="light-textblack text-montserrat fw-bold">
                Standard
              </h5>
              <span className="light-text text-montserrat fw-normal">
                Make the best for your Personal Use
              </span>
              <p className="large text-lightblack text-montserrat fw-normal">
                $10
              </p>
            </div>
            <div className="center">
              <span className="text-lightblack text-montserrat fw-normal">
                The $10 package includes a total of 25 trials, NOT per feature.
              </span>
              <div className="features">
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI CV Maker
                    </p>
                  </div>
                  <ul>
                    <li>Formats - pdf, docx, txt</li>
                    <li>Customizable Templates</li>
                    <li>Watermark removed</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Imagify
                    </p>
                  </div>
                  <ul>
                    <li>Formats - svg, png, jpg, jpeg, pdf</li>
                    <li>Custom aspect ratios etc.</li>
                    <li>More Resolution</li>
                    <li>Watermark removed</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Vocals
                    </p>
                  </div>
                  <ul>
                    <li>Text to Speech</li>
                    <li>Speech to Text</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Summaros
                    </p>
                  </div>
                  <ul>
                    <li>No. of pages</li>
                    <li>Formats - docx, excel, txt etc</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Quiz Booster
                    </p>
                  </div>
                  <ul>
                    <li>Custom topics</li>
                    <li>Document upload</li>
                  </ul>
                </div>
              </div>
            </div>

            <Button
              className="primary-btn small-btn fw-medium rounded"
              text="Subscribe"
              width="100%"
              height="48.47px"
            />
          </div>
          <div className="ist">
            <div className="top">
              <h5 className="light-textblack text-montserrat fw-bold">
                Premium
              </h5>
              <span className="light-text text-montserrat fw-normal">
                Tailored for Small Team Use
              </span>
              <p className="large text-lightblack text-montserrat fw-normal">
                $20
              </p>
            </div>
            <div className="center">
              <span className="text-lightblack text-montserrat fw-normal">
                The $20 package includes a total of 25 trials, NOT per feature.
              </span>
              <div className="features">
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI CV Maker
                    </p>
                  </div>
                  <ul>
                    <li>Formats - pdf, docx, txt</li>
                    <li>Customizable Templates</li>
                    <li>Watermark removed</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Imagify
                    </p>
                  </div>
                  <ul>
                    <li>Formats - svg, png, jpg, jpeg, pdf</li>
                    <li>Custom aspect ratios etc.</li>
                    <li>More Resolution</li>
                    <li>Watermark removed</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Vocals
                    </p>
                  </div>
                  <ul>
                    <li>Text to Speech</li>
                    <li>Speech to Text</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Summaros
                    </p>
                  </div>
                  <ul>
                    <li>No. of pages</li>
                    <li>Formats - docx, excel, txt etc</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Quiz Booster
                    </p>
                  </div>
                  <ul>
                    <li>Custom topics</li>
                    <li>Document upload</li>
                  </ul>
                </div>
              </div>
            </div>

            <Button
              className="primary-btn small-btn fw-medium rounded"
              text="Subscribe"
              width="100%"
              height="48.47px"
            />
          </div>
          <div className="ist">
            <div className="top">
              <h5 className="light-textblack text-montserrat fw-bold">
                Custom
              </h5>
              <span className="light-text text-montserrat fw-normal">
                Perfect for Businesses and Teams
              </span>
              <p className="large text-lightblack text-montserrat fw-normal">
                $25
              </p>
            </div>
            <div className="center">
              <span className="text-lightblack text-montserrat fw-normal">
                With our custom plan, starting from $25, you can enjoy a
                tailored package with increased trial allocations to your needs.
              </span>
              <div className="features">
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI CV Maker
                    </p>
                  </div>
                  <ul>
                    <li>Formats - pdf, docx, txt</li>
                    <li>Customizable Templates</li>
                    <li>Watermark removed</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Imagify
                    </p>
                  </div>
                  <ul>
                    <li>Formats - svg, png, jpg, jpeg, pdf</li>
                    <li>Custom aspect ratios etc.</li>
                    <li>More Resolution</li>
                    <li>Watermark removed</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Vocals
                    </p>
                  </div>
                  <ul>
                    <li>Text to Speech</li>
                    <li>Speech to Text</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Summaros
                    </p>
                  </div>
                  <ul>
                    <li>No. of pages</li>
                    <li>Formats - docx, excel, txt etc</li>
                  </ul>
                </div>
                <div className="feature">
                  <div className="d-flex align-items-center gap-3">
                    <img src="/assets/icons/pricingcheck.svg" alt="..." />
                    <p className="small text-lightblack text-montserrat fw-bold">
                      AI Quiz Booster
                    </p>
                  </div>
                  <ul>
                    <li>Custom topics</li>
                    <li>Document upload</li>
                  </ul>
                </div>
              </div>
            </div>

            <Button
              className="primary-btn small-btn fw-medium rounded"
              text="Subscribe"
              width="100%"
              height="48.47px"
            />
          </div>
        </div>
      </div>
      <div className="back-div">
        <div></div>
      </div>
    </section>
  );
}

export default Pricing;
