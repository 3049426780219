import React from "react";
import "./statistics.scss";
import Heading from "../heading/heading";
function Statistics() {
  return (
    <section className="statistics-sec">
      <Heading maintext="STATISTICS" subtext="Our Statistics" />
      <div className="inner">
        <div className="stat-cards">
          <div className="stat-card">
            <img src="/assets/icons/subscription.svg" alt="..." />
            <div className="d-flex flex-column align-items-center">
              <h6 className="text-montserrat fw-semibold text-black">
                AI workforce
              </h6>
              <p className="small fw-semibold text-black text-center">
                97 million by 2025
              </p>
            </div>
            <div className="card-circle"></div>
          </div>
          <div className="stat-card">
            <img src="/assets/icons/enterprise.svg" alt="..." />
            <div className="d-flex flex-column align-items-center">
              <h6 className="text-montserrat fw-semibold text-black">
                AI priority
              </h6>
              <p className="small fw-semibold text-black text-center">
                83% companies
              </p>
            </div>
            <div className="card-circle"></div>
          </div>
          <div className="stat-card">
            <img src="/assets/icons/transactionfee.svg" alt="..." />
            <div className="d-flex flex-column align-items-center">
              <h6 className="text-montserrat fw-semibold text-black">
                Productivity boost
              </h6>
              <p className="small fw-semibold text-black text-center">
                64% companies
              </p>
            </div>
            <div className="card-circle"></div>
          </div>
          <div className="stat-card">
            <img src="/assets/icons/apifee.svg" alt="..." />
            <div className="d-flex flex-column align-items-center">
              <h6 className="text-montserrat fw-semibold text-black">
                Competitive edge
              </h6>
              <p className="small fw-semibold text-black text-center">
                87% multinationals
              </p>
            </div>
            <div className="card-circle"></div>
          </div>
          <div className="stat-card">
            <img src="/assets/icons/ecosystem.svg" alt="..." />
            <div className="d-flex flex-column align-items-center">
              <h6 className="text-montserrat fw-semibold text-black">
                AI market
              </h6>
              <p className="small fw-semibold text-black text-center">
                $407 billion by 2027
              </p>
            </div>
            <div className="card-circle"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Statistics;
