import React from "react";
import Secondheading from "../secondheading/secondheading";
import HelpYou from "../aiSummaros/helpYou";
import SummarizeContent from "../aiSummaros/summarizeContent";
import Features from "../aiSummaros/features";
import imgDashboard from "../../assets/images/ai-imagify/dashboardImg.webp";
import AiImages from "../aiImages/aiImages";
import RoboSection from "../aiSummaros/roboSection";
import Benefits from "../aiSummaros/benefits";
import Trusted from "../trusted/trusted";
import Heading from "../heading/heading";

const AiImagify = () => {
  const imgDash = imgDashboard;
  return (
    <div className="top-sec-padding">
      <Secondheading
        head1="Generate Your"
        head2={["Art", "Images"]}
        head3="with AI Imagify"
        para="Experience the magic of instant art creation with AI Imagify, your go-to for stunning images in seconds. "
        addBrTag={true}
      />
      <HelpYou
        typewriterMessage={[
          "Give me the images of the Animated cartoon in 3d format.",
          "A creative image of a car sitting on sofa .",
          "Generate an image of a turtle swimming underwater , expressionist painting .",
        ]}
      />
      <SummarizeContent imgs={imgDash} />
      <Heading maintext="FEATURES" subtext="AI Imagify Features" />
      <Features />
      <Heading maintext="AI IMAGES" subtext="Images generated with AI" />
      <AiImages />
      <RoboSection
        h1="Empower your creativity with"
        span=" AI Imagify"
        p="Turn your imagination into reality with AI Imagify's instant art generation capabilities, transforming ideas into visuals rapidly."
      />
      <Heading maintext="BENEFITS" subtext="AI Imagify Benefits" />
      <Benefits />
      <Trusted />
    </div>
  );
};

export default AiImagify;
