import React, { useState } from "react";
import "./hero.scss";
import Button from "../button/button";
import { NavLink } from "react-router-dom";
import { Modal } from "antd";
import portfolioVideo from "../../assets/videos/portfolioVideo.mp4";
function Hero() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <section className="hero-sec">
      <div className="left">
        <h1 className="text-montserrat fw-bold">
          ALL IN ONE
          <span className="text-main"> AI SOLUTION!</span>
        </h1>
        <p className="fw-medium text-montserrat">
          Welcome to Mister AI! Your all-in-one destination for utilizing the
          incredible potential of artificial intelligence to make your life
          easier. Whether you're a student, a professional, or an individual
          seeking to streamline daily tasks, Mister AI has everything you need
          to succeed.
        </p>
        <div className="buttons mt-4">
          <NavLink to="/demo">
            <Button
              className="primary-btn large-btn fw-medium rounded"
              text="Request Demo"
              width="209px"
              height="58px"
            />
          </NavLink>

          <Button
            className="secondary-btn large-btn fw-medium rounded"
            text="Teaser"
            width="222px"
            height="58px"
            //onClick={showModal}
          />

          <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            className="videoModal"
            footer={null}
          >
            <video loop autoPlay playsInline muted>
              <source src={portfolioVideo} type="video/mp4" />
            </video>
          </Modal>
        </div>
      </div>
      <div className="right">
        <img
          src="/assets/images/heromain.webp"
          alt="..."
          className="heromain-img"
        />

        <img
          src="/assets/images/heroai.webp"
          alt="..."
          className="heroai-img"
        />
      </div>
      <div className="social-links">
        <a href="  https://t.me/misteraicommunity" target="_blank">
          <img
            src=" /assets/icons/iconoir--telegram-circle.png"
            alt="Telegram"
          />
        </a>
        <a href="https://x.com/misterai_info" target="_blank">
          <img src="/assets/icons/herotwitter.svg" alt="..." />
        </a>
        <a href="https://discord.gg/XKZGnArzYK" target="_blank">
          <img src=" /assets/icons/simple-icons--discord.png" alt="..." />
        </a>
      </div>
    </section>
  );
}

export default Hero;
