import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import "./roadmap.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Roadmap() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2.3,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const slider = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const ele = slider.current;

    if (window.innerWidth >= 992 && ele) {
      gsap.registerPlugin(ScrollTrigger);

      const rightSections = gsap.utils.toArray(".service-slider__item");

      const pin = gsap.to(rightSections, {
        xPercent: -100 * (rightSections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ele,
          pin: true,
          invalidateOnRefresh: true,
          start: "center center-=100",
          scrub: 1,
          end: () => "+=" + (slider.current?.offsetWidth || 0),
          markers: false,
        },
      });

      return () => {
        pin.kill();
      };
    }
  }, []);

  return (
    <>
      <sec className="roadmap-sec">
        <div className="service-slider_wrapper" ref={slider}>
          <div className="service-slider d-flex align-items-center">
            <div className="service-slider__item">
              <a>
                <img src="/assets/images/roadmap/2.webp" alt="Image" />
              </a>
              <div className="quarter1">
                <p className="large fw-semibold text-black text-jakarta">
                  Mister AI
                </p>
                <div className="features">
                  <p className="small fw-normal text-montserrat text-black">
                    AI CV Maker
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Quiz Booster
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Content Pro
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Imagify
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Summaros
                  </p>
                </div>
              </div>
              <div className="quarter2">
                <p className="large fw-semibold text-black text-jakarta">
                  Mister AI
                </p>
                <div className="features">
                  <p className="small fw-normal text-montserrat text-black">
                    AI Video Creator
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Digibill
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Chat Sparks
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Web Build
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Coder
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Vocals
                  </p>
                </div>
              </div>
              <div className="quarter3">
                <p className="large fw-semibold text-black text-jakarta">
                  Mister AI
                </p>
                <div className="features">
                  <p className="small fw-normal text-montserrat text-black">
                    AI Deployer
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Task Assist
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Sports Oracle
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Vigil Cam
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Quake Watch
                  </p>
                </div>
              </div>
              <div className="quarter4">
                <p className="large fw-semibold text-black text-jakarta">
                  Mister AI
                </p>
                <div className="features">
                  <p className="small fw-normal text-montserrat text-black">
                    AI Talk Wise
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Autografiq
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Stockify
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Infectrace
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Medico Aide
                  </p>
                </div>
              </div>
              <div className="quarter5">
                <p className="large fw-semibold text-black text-jakarta">
                  Mister AI
                </p>
                <div className="features">
                  <p className="small fw-normal text-montserrat text-black">
                    AI Sculptera
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Tutor
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Sea Watch
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Pitch Pro
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Astro Star
                  </p>
                </div>
              </div>
              <div className="quarter6">
                <p className="large fw-semibold text-black text-jakarta">
                  Mister AI
                </p>
                <div className="features">
                  <p className="small fw-normal text-montserrat text-black">
                    AI Guider
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Game Mentor
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Translator
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Tune Composer
                  </p>
                  <p className="small fw-normal text-montserrat text-black">
                    AI Look Up
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="service-slider d-flex align-items-center ">
            <div className="service-slider__item"></div>
          </div>
        </div>
      </sec>
      <div className="mob-roadmap">
        <Slider {...settings} className="roadmap-slider">
          <div>
            <img src="/assets/images/roadmap/mb/1.webp" alt="" />
          </div>

          <div>
            <img src="/assets/images/roadmap/mb/2.webp" alt="" />
          </div>

          <div>
            <img src="/assets/images/roadmap/mb/3.webp" alt="" />
          </div>

          <div>
            <img src="/assets/images/roadmap/mb/4.webp" alt="" />
          </div>

          <div>
            <img src="/assets/images/roadmap/mb/5.webp" alt="" />
          </div>

          <div>
            <img src="/assets/images/roadmap/mb/6.webp" alt="" />
          </div>

          {/* <img src="/assets/images/roadmap/mb/7.png" alt="" /> */}
        </Slider>
      </div>
    </>
  );
}

export default Roadmap;
