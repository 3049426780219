import React from 'react';
import Heading from '../heading/heading';
import ContactForm from './contactForm';
import "../demo/contactForm.scss"

const Demo = () => {
  return (
   <div className='mt-10 aaa'>
   
<Heading  maintext="DEMO" subtext="Request Demo!" />
<ContactForm/>
   </div>
  )
}

export default Demo;