import React from "react";
import "../aiSummaros/features.scss";
import FeatureCard from "./featureCard";
import yellowGlow from "../../../src/assets/images/aiSummaros/feature/yellowGlow.webp";
import card1img from "../../../src/assets/images/aiSummaros/feature/card1Img.svg";
import card2img from "../../../src/assets/images/aiSummaros/feature/card2Img.svg";
import card3img from "../../../src/assets/images/aiSummaros/feature/card3Img.svg";
import card4img from "../../../src/assets/images/aiSummaros/feature/card4Img.svg";
import card5img from "../../../src/assets/images/aiSummaros/feature/card5Img.svg";
import Heading from "../heading/heading";
import { useLocation } from "react-router-dom";

const Features = () => {
  const location = useLocation();

  const DataSet = [
    {
      AiSummaros: [
        {
          id: 1,

          img: card1img,
          head: "Customizable Summarization",
          para: "Tailor your summaries to your specific needs by inputting the text you want summarized and specifying the desired length or key points to focus on. ",
        },
        {
          id: 2,

          img: card2img,
          head: "Coherent Summaries",
          para: "Harness the power of artificial intelligence algorithms to generate concise and coherent summaries from lengthy articles, documents, or texts.",
        },

        {
          id: 3,

          img: card3img,
          head: "Key Point Extraction",
          para: "Identify and extract the most important key points and insights from the original text, ensuring that your summary captures the essence of the content.",
        },

        {
          id: 4,

          img: card4img,
          head: "Export Options",
          para: "Download your summaries in various formats, including plain text or PDF, for easy sharing, reference, or integration into reports and presentations.",
        },

        {
          id: 5,

          img: card5img,
          head:  "Real-Time Preview",
          para: "Preview your summary in real-time as you customize the parameters, allowing for quick adjustments and revisions until you're satisfied with the result.",
        },
      ],
    },
    {
      AImagify: [
        {
          id: 1,
          img: card1img,
          head: "Imagify Prompt Input",
          para: "Users can input prompts or descriptions, and our AI-powered Imagify feature transforms these inputs into stunning visual representations.",
        },
        {
          id: 2,
          img: card2img,
          head: "Versatile Image Generation",
          para: "Generate a wide variety of images, ranging from abstract concepts to concrete objects, based on the prompts provided by users.",
        },

        {
          id: 3,
          img: card3img,
          head: "Customizable Styles and Themes",
          para: "Tailor the style and theme of generated images to match specific preferences or project requirements, ensuring flexibility and creative control.",
        },

        {
          id: 4,
          img: card4img,
          head: "Real-Time Rendering",
          para: "Experience instant rendering of images based on user prompts, allowing for quick iteration and refinement until the desired result is achieved.",
        },

        {
          id: 5,
          img: card5img,
          head: "High-Quality Output",
          para: "Produce high-resolution images with exceptional clarity and detail, suitable for use in a variety of applications, including digital and print media.",
        },
      ],
    },
    {
      ContentPro: [
        {
          id: 1,
          img: card1img,
          head: "AI Blog Generation",
          para: "Utilize our AI-powered blog generator, AI Content Pro, to effortlessly create high-quality blog posts tailored to your specifications",
        },
        {
          id: 2,
          img: card2img,
          head: " SEO-Optimized Content",
          para: "Receive intelligent suggestions for SEO tags and keywords to optimize your blog posts for search engine visibility and improve organic traffic.",
        },

        {
          id: 3,
          img: card3img,
          head: "Image Integration",
          para: "Automatically incorporate relevant images into your blog posts, enhancing visual appeal and reader engagement, with options for customization based on your preferences.",
        },

        {
          id: 4,
          img: card4img,
          head: "Choose any Niche",
          para: "Whether you are writing a blog about tech trends or sports leagues, AI Content Pro will generate it in a few seconds tailored to your requirements.",
        },

        {
          id: 5,
          img: card5img,
          head: "Tailored for targeted Audience",
          para: "AI Content Pro generates such engaging and attractive blogs that are tailored according to your targeted audience's needs.",
        },
      ],
    },

    {
      AiQuiz: [
        {
          id: 1,
          img: card1img,
          head: "Diverse Quiz Categories",
          para: "Explore a wide range of quiz categories, covering various topics and subjects, to enhance your knowledge and skills in areas of interest.",
        },
        {
          id: 2,
          img: card2img,
          head: "Customizable Difficulty Levels",
          para: "Adjust the difficulty levels of quizzes to match your proficiency, whether you're a beginner looking to learn or an expert seeking a challenge.",
        },

        {
          id: 3,
          img: card3img,
          head: " AI-Powered Training",
          para: "Access AI-driven training modules in the paid version, allowing you to receive personalized guidance and feedback tailored to your learning goals.",
        },

        {
          id: 4,
          img: card4img,
          head: "Multiplayer Mode",
          para: "Challenge friends or compete against other users in multiplayer quiz matches, fostering a sense of camaraderie and healthy competition.",
        },

        {
          id: 5,
          img: card5img,
          head: "Comprehensive Analytics",
          para: "Track your progress and performance with detailed analytics and insights, allowing you to identify strengths, weaknesses, and areas for improvement.",
        },
      ],
    },



    {
      CvMaker: [
        {
          id: 1,
          img: card1img,
          head: "AI-Powered Resume Generation",
          para: "Harness the power of cutting-edge artificial intelligence to create professional resumes/cv tailored to your industry and career level.",
        },
        {
          id: 2,
          img: card2img,
          head: "Personalized Templates",
          para: "Choose from a wide array of customizable templates designed by professionals to suit your style and preference.",
        },

        {
          id: 3,
          img: card3img,
          head: "Smart Suggestions",
          para: "Receive intelligent suggestions on content, formatting, and keywords to optimize your resume for maximum impact.",
        },

        {
          id: 4,
          img: card4img,
          head: "Instant Preview",
          para: "Get a real-time preview of your resume/cv as you make changes, ensuring it looks polished and professional every step of the way.",
        },

        {
          id: 5,
          img: card5img,
          head: "Multi-Format Export",
          para: "Download your resume in various formats such as PDF, DOCX, or plain text, ensuring compatibility with any application process.",
        },

      
      ],
    },
  ];

  const renderFeatureCards = () => {
    const pathname = location.pathname;

    if (pathname === "/ai-summaros") {
      return DataSet[0].AiSummaros.map((item) => (
        <FeatureCard
          key={item.id}
          imgs={item.img}
          heading={item.head}
          paragraph={item.para}
        />
      ));
    } else if (pathname === "/ai-imagify") {
      return DataSet[1].AImagify.map((item) => (
        <FeatureCard
          key={item.id}
          imgs={item.img}
          heading={item.head}
          paragraph={item.para}
        />
      ));
    } else if (pathname === "/content-pro") {
      return DataSet[2].ContentPro.map((item) => (
        <FeatureCard
          key={item.id}
          imgs={item.img}
          heading={item.head}
          paragraph={item.para}
        />
      ));
    } else if (pathname === "/ai-quiz") {
      return DataSet[3].AiQuiz.map((item) => (
        <FeatureCard
          key={item.id}
          imgs={item.img}
          heading={item.head}
          paragraph={item.para}
        />
      ));
    }

    else if (pathname === "/cv-maker") {
      return DataSet[4].CvMaker.map((item) => (
        <FeatureCard
          key={item.id}
          imgs={item.img}
          heading={item.head}
          paragraph={item.para}
        />
      ));
    }
    
    else {
      return null;
    }
  };

  return (
    <div className="featureWrapper">
      {/* <div className="headingArea">
        <h1 className="text-montserrat  fw-bold ">FEATURES</h1>
        <h2 className="text-montserrat fw-bold text-center text-blak">
          AI Summaros Features
        </h2>
      </div> */}

      <div className="featureCardContainer mt-5">
        <img className="yellowGlow" src={yellowGlow} alt="" />

        {renderFeatureCards()}
      </div>
    </div>
  );
};

export default Features;
