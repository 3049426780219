import React, { useState } from "react";
import "./faqs.scss";
import { Accordion } from "react-bootstrap";
import Heading from "../heading/heading";
function Faqs() {
  const [activeKey, setActiveKey] = useState("0");
  const handleAccordionSelect = (selectedKey) => {
    setActiveKey(selectedKey);
  };
  return (
    <section className="faqs-sec">
      <Heading maintext="FAQS" subtext="Quick Q&A" />
      <div className="inner">
        <div className="faqs-accordion">
          <Accordion
            defaultActiveKey="0"
            activeKey={activeKey}
            onSelect={handleAccordionSelect}
            flush
          >
            {[0, 1, 2, 3, 4, 5].map((itemKey) => (
              <Accordion.Item
                key={itemKey}
                eventKey={itemKey.toString()}
                style={{
                  boxShadow:
                    activeKey === itemKey.toString()
                      ? "0px 0px 16.033px 0px #FB0 "
                      : "0px 5.01px 16.033px 0px rgba(8, 15, 52, 0.06)",
                }}
              >
                <Accordion.Header>
                  {itemKey === 0
                    ? "What is Mister AI?"
                    : itemKey === 1
                    ? " Who can benefit from using Mister AI?"
                    : itemKey === 2
                    ? "What are the pricing options available for Mister AI?"
                    : itemKey === 3
                    ? "How do I get started with Mister AI?"
                    : itemKey === 4
                    ? "Is there support available if I have questions or encounter issues?"
                    : "Can I integrate Mister AI with other software and platforms?"}
                </Accordion.Header>
                <Accordion.Body>
                  {itemKey === 0
                    ? "Mister AI is an innovative platform that provides a wide range of AI tools and solutions for various applications. It serves as a one-stop destination for individuals, professionals, and businesses looking to leverage artificial intelligence in their endeavors."
                    : itemKey === 1
                    ? "Mister AI is designed to benefit a wide range of users, including students, researchers, entrepreneurs, marketers, data scientists, and business professionals. Whether you're working on academic projects, optimizing business processes, or exploring AI concepts, Mister AI has something to offer."
                    : itemKey === 2
                    ? "Mister AI offers both Free and Premium packages to cater to different user needs. The Free package provides access to basic AI tools with limited usage, while the Premium package offers unlimited access to all tools, advanced customization options, priority support, and exclusive features."
                    : itemKey === 3
                    ? "Getting started with Mister AI is simple. Just sign up for an account on our website, choose the package that best suits your needs, and start exploring our AI tools and solutions. Our user-friendly interface makes it easy to navigate and utilize the platform, regardless of your level of expertise."
                    : itemKey === 4
                    ? "Absolutely. Mister AI provides comprehensive support to users, including documentation, tutorials, and a dedicated customer support team. If you have any questions, encounter technical issues, or need assistance with using the platform, our team is here to help you every step of the way."
                    : "Yes, Mister AI offers seamless integration with a variety of software and platforms, including popular productivity suites and cloud computing services. Whether you're working within a specific ecosystem or utilizing third-party tools, Mister AI can be easily integrated to enhance your workflow and productivity."}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
      <div className="left-back"></div>
      <div className="right-back"></div>
    </section>
  );
}

export default Faqs;
