import React from "react";
import Secondheading from "../secondheading/secondheading";
import HelpYou from "../aiSummaros/helpYou";
import SummarizeContent from "../aiSummaros/summarizeContent";
import Features from "../aiSummaros/features";
import RoboSection from "../aiSummaros/roboSection";
import Benefits from "../aiSummaros/benefits";
import Trusted from "../trusted/trusted";
import cvImg from "../../assets/images/cv/cvImg.png";
import Heading from "../heading/heading";

const CvMaker = () => {
  const imgDash = cvImg;
  return (
    <div className="top-sec-padding">
      <Secondheading
        head1="Refine Your"
        head2={[" Career ", "Profession "]}
        head3="Presentation"
        para="Polish your career presentation and CV to shine brighter in your professional journey"
        addBrTag={true}
      />
      <HelpYou
        typewriterMessage={[
          "Create a professional CV suitable for marketing specialist  position .",
          "Generate a professional  resume for the recent college graduate.",
        ]}
      />
      <SummarizeContent imgs={imgDash} />
      <Heading maintext="FEATURES" subtext="CV Maker Features" />
      <Features />
      <RoboSection
        h1="Boost your career with"
        span=" AI CV Maker"
        p="Transform your career trajectory and stand out professionally with AI CV Maker's advanced resume solutions."
      />
      <Heading maintext="BENEFITS" subtext="CV Maker Benefits" />
      <Benefits />
      <Trusted
        h2="Be a Bigger Part of the Future"
        p="Join Mister AI and be a part of its growing community to support the vision to bring AI solutions to the world at every stage of life. "
      />
    </div>
  );
};

export default CvMaker;
