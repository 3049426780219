import React from "react";
import "./blogpage.scss";
function Blogpage() {
  return (
    <section className="blogpage-sec">
      <div className="inner">
        <img
          src="/assets/images/blogs/bloghead.webp"
          alt="..."
          className="head-img"
        />
        <div className="content">
          <div className="ist">
            <img
              src="/assets/images/blogs/blogmain.webp"
              alt="..."
              className="blog-main"
            />
            <h6 className="fw-medium text-montserrat">
              <span className="text-main">Feb 02, 2024 </span>[Automation,
              Trending]
            </h6>
          </div>
          <div className="second">
            <h3 className="fw-bold">
              Web 3.0: The Future of the Internet Unveiled
            </h3>
            <div className="paras">
              <p className="large fw-normal">
                Unveil the future of the internet with 'Web 3.0.' Explore the
                decentralized, interactive landscape that is reshaping online
                experiences, promising a user-centric web revolution.
              </p>
              <p className="large fw-normal">
                Web 3.0 transcends being a buzzword; it's a reimagination of the
                internet's DNA. This phase marks a departure from centralized
                systems, introducing decentralized, user-centric protocols. The
                goal is to create an internet where users wield more control
                over their data, fostering transparency and enabling
                peer-to-peer interactions.
              </p>
              <p className="large fw-normal">
                In the Web 3.0 landscape, power dynamics are shifting.
                Decentralized applications (DApps) and blockchain technology
                challenge traditional models of centralized control. With
                advancements in artificial intelligence and machine learning, a
                deeper understanding of context, intent, and content is
                achieved, providing users with a more personalized and
                intelligent digital experience. Interoperability breaks down
                silos, creating a more connected digital ecosystem.
              </p>
              <p className="large fw-normal">
                Web 3.0 isn't a distant vision; it's already making waves in
                decentralized finance (DeFi), non-fungible tokens (NFTs), and
                the metaverse, promising a more inclusive, secure, and
                user-centric internet experience. As we step into this era,
                embracing change, adapting to new technologies, and actively
                participating in the ongoing dialogue will be crucial. The
                journey ahead is filled with possibilities and innovations that
                will redefine how we engage with the digital world, making Web
                3.0 not just the next chapter but a whole new narrative in the
                story of the internet. Buckle up as we embark on this thrilling
                journey into the digital frontier, where the future of the
                internet unfolds before our eyes.
              </p>
            </div>
          </div>
          <div className="third">
            <div className="left">
              <img src="/assets/images/blogs/bloginner1.webp" alt="..." />
            </div>
            <div className="right">
              <h3 className="fw-bold">Web 3.0</h3>
              <p className="large fw-normal">
                Web 3.0 isn't a distant vision; it's already making waves in
                decentralized finance (DeFi), non-fungible tokens (NFTs), and
                the metaverse, promising a more inclusive, secure, and
                user-centric internet experience. As we step into this era,
                embracing change, adapting to new technologies, and actively
                participating in the ongoing dialogue will be crucial. The
                journey ahead is filled with possibilities and innovations that
                will redefine how we engage with the digital world, making Web
                3.0 not just the next chapter but a whole new narrative in the
                story of the internet. Buckle up as we embark on this thrilling
                journey into the digital frontier, where the future of the
                internet unfolds before our eyes.
              </p>
            </div>
          </div>
          <div className="forth">
            <div className="left">
              <h3 className="fw-bold">Web 3.0</h3>
              <p className="large fw-normal">
                Web 3.0 isn't a distant vision; it's already making waves in
                decentralized finance (DeFi), non-fungible tokens (NFTs), and
                the metaverse, promising a more inclusive, secure, and
                user-centric internet experience. As we step into this era,
                embracing change, adapting to new technologies, and actively
                participating in the ongoing dialogue will be crucial. The
                journey ahead is filled with possibilities and innovations that
                will redefine how we engage with the digital world, making Web
                3.0 not just the next chapter but a whole new narrative in the
                story of the internet. Buckle up as we embark on this thrilling
                journey into the digital frontier, where the future of the
                internet unfolds before our eyes.
              </p>
            </div>
            <div className="right">
              <img src="/assets/images/blogs/bloginner1.webp" alt="..." />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blogpage;
