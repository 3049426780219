import React from "react";
import "./trusted.scss";
import Button from "../button/button";

function Trusted({ h2, p }) {
  return (
    <section className="trusted-sec">
      <div className="inner">
        <Button
          className="primary-btn large-btn fw-medium rounded-pill"
          text="Join the Community"
          width="325px"
          height="59px"
        />
        <h2 className="fw-bold">{h2}</h2>
        <p className="medium">{p}</p>
        <img src="/assets/images/trusted.webp" alt="" />
      </div>
      <div className="back-layer"></div>
    </section>
  );
}

export default Trusted;
