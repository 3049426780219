import React from "react";
import "./about.scss";
import Heading from "../heading/heading";
function About() {
  return (
    <section className="about-sec">
      <Heading maintext="ABOUT US" subtext="Our Story" />
      <div className="inner">
        <div className="left">
          <img src="/assets/images/about.webp" alt="..." />
          <div className="backcolor"></div>
        </div>
        <div className="right">
          <h3 className="fw-bold text-montserrat">
            <span className="text-main ">Who</span> Are We?
          </h3>
          <p className="text-montserrat fw-normal">
            Mister AI is a groundbreaking one-stop-shop on the Solana
            blockchain, pioneering the fusion of Web3 technology with artificial
            intelligence. Offering a comprehensive suite of AI tools across
            domains, Mister AI empowers users with intuitive customization,
            seamless integration, and dedicated support. By leveraging the
            Solana blockchain, Mister AI ensures transparency, security, and
            efficiency, setting a new standard for AI platforms in the Web3 era.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
