import React from "react";
import Secondheading from "../secondheading/secondheading";
import HelpYou from "../aiSummaros/helpYou";
import SummarizeContent from "../aiSummaros/summarizeContent";
import imgDashboard from "../../assets/images/contentPro/contentProDashImg.webp";
import Features from "../aiSummaros/features";
import RoboSection from "../aiSummaros/roboSection";
import Benefits from "../aiSummaros/benefits";
import Trusted from "../trusted/trusted";
import AiAssistent from "./aiAssistent";
import Heading from "../heading/heading";

const ContentPro = () => {
  const imgDash = imgDashboard;
  return (
    <div className="top-sec-padding">
      <Secondheading
        head1="Get Pro"
        head2={[" Content", "Blog", "Article"]}
        head3="in Seconds!"
        para="Experience Instant, Professional Content Creation with AI Content Pro in Just Seconds!"
        addBrTag={true}
      />
      <HelpYou
        typewriterMessage={[
          "Write a short article about space exploration.",
          "Generate a blog post on sustainable living practices.",
          "Generate blog post on artificial intelligence in 300 words.",
        ]}
      />
      <SummarizeContent imgs={imgDash} />
      <Heading maintext="FEATURES" subtext="Content Pro Features" />
      <Features />
      <AiAssistent />
      <RoboSection
        h1="SAVE TIME AND EFFORT WITH"
        span=" AI CONTENT PRO"
        p="Unlock your writing potential with ai content pro and effortlessly create professional content in no time!"
      />
      <Heading maintext="BENEFITS" subtext="Content Pro Benefits" />
      <Benefits />
      <Trusted />
    </div>
  );
};

export default ContentPro;
