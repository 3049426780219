import React from "react";
import HelpYou from "../aiSummaros/helpYou";
import SummarizeContent from "../aiSummaros/summarizeContent";
import Features from "../aiSummaros/features";
import Secondheading from "../secondheading/secondheading";
import aiQuizImg from "../../assets/images/aiQuiz/aiQuizImg.png";
import IqLevel from "./iqLevel";
import RoboSection from "../aiSummaros/roboSection";
import Benefits from "../aiSummaros/benefits";
import Trusted from "../trusted/trusted";
import Heading from "../heading/heading";

const AiQuiz = () => {
  const imgDash = aiQuizImg;
  return (
    <div className="top-sec-padding">
      <Secondheading
        head1="Get Your Brain"
        head2={[" Challenged ", "Contested "]}
        head3="With AI Quizzes"
        para="Engage in AI Quizzes to Challenge and Expand Your Knowledge"
        addBrTag={true}
      />
      <HelpYou
        typewriterMessage={[
          "Create a quiz about animals with 10 easy questions.",
          "Design a quiz on major historical events of the 20th century.",
          "Generate a quiz on history with 15 questions of medium difficulty.",
        ]}
      />
      <SummarizeContent imgs={imgDash} />
      <Heading maintext="FEATURES" subtext="Quiz Booster Features" />
      <Features />
      <IqLevel />
      <RoboSection
        h1="Sharpen Your Wits With"
        span=" AI Quiz Booster"
        p="Challenge Yourself with AI Quiz Booster for a Stimulating Brain Workout and Learning Adventure"
      />
      <Heading maintext="BENEFITS" subtext="Quiz Booster Benefits" />
      <Benefits />
      <Trusted
        h2="Be a Bigger Part of the Future"
        p="Join Mister AI and be a part of its growing community to support the vision to bring AI solutions to the world at every stage of life. "
      />
    </div>
  );
};

export default AiQuiz;
